import Rails from 'rails-ujs';
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [ 'form' ];

  connect() {
    const params = new URLSearchParams(window.location.search);
    var blank = new Option("エリアを選択", "", false, false)

    while (this.formTarget['city_id'].options.length > 0) {
      this.formTarget['city_id'].options.remove(0);
    }
    this.formTarget['city_id'].add(blank);

    this.makeCityOption(params.get('prefecture_id')).forEach(elem => this.formTarget['city_id'].add(elem));
    if(params.get('city_id')) this.formTarget['city_id'].value = params.get('city_id');

    this.formTarget['city_id'].disabled = !params.get('prefecture_id');
  }

  prefectureUpdate(event) {
    const formdata = new FormData(this.formTarget);
    const prefectures = formdata.get('prefecture_id');
    var blank = new Option("エリアを選択", "", false, false)

    while (this.formTarget['city_id'].options.length > 0) {
      this.formTarget['city_id'].options.remove(0);
    }
    this.formTarget['city_id'].add(blank);
    this.makeCityOption(prefectures).forEach(elem => this.formTarget['city_id'].add(elem));

    this.formTarget['city_id'].disabled = !prefectures;
  }

  changeOrder(event) {
    this.formTarget.submit();
  }

  makeCityOption(prefecture_id) {
    return this.cities.filter(elem => elem.prefecture_id == prefecture_id).map(elem => {
      return new Option(elem.name, elem.id, false, false);
    });
  }

  get prefectures() {
    return JSON.parse(this.data.get('prefectures'));
  }

  get cities() {
    return JSON.parse(this.data.get('cities'));
  }
}
