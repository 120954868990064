import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    this.set_events();
  }

  /*
   * イベント設定
   */
  set_events() {
    $(function(){
      $('#area_search_submit').prop('disabled', true);
        $("input[type='checkbox']").on('change', function () {
            if ($('.custom-checkbox-input:checked').length > 0) {
              $('#area_search_submit').prop('disabled', false);
            } else {
              $('#area_search_submit').prop('disabled', true);
            }
        });
    });
  }
}
