import { Controller } from 'stimulus';
import Rails from 'rails-ujs';

export default class extends Controller {
  static targets = [ 'checkBoxHitReinsOfIntellex' ]

  onCheckHitReinsOfIntellex() {
    this.upDateHitReinsOfIntellex()
  }

  upDateHitReinsOfIntellex(){
    Rails.ajax({
      type: 'PATCH',
      dataType: 'json',
      url: `/api/admin_requie/${ this.checkBoxHitReinsOfIntellexTarget.defaultValue }/update_hit_reins_of_intellex?hit_reins_of_intellex=${ this.checkBoxHitReinsOfIntellexTarget.checked }`,
      success: (json) => {
        this.checkBoxHitReinsOfIntellexTarget.disabled = true
      },
      error: () => {
        alert('エラーが発生しました。ブラウザをリロードして、再度お試しください。')
      }
    })
  }
}
