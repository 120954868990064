import Rails from 'rails-ujs';
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [ 'memo', 'button', 'transferButton' ]

  openModal() {
    const feedbackId = this.feedbackId
    const callLogId = this.callLogId

    if (feedbackId) {
      document.getElementById('#modal-customer-transfer-form-' + feedbackId).click()
    } else if (callLogId) {
      document.getElementById('#modal-customer-transfer-form-' + callLogId).click()
    }
  }

  createMemo() {
    this.buttonTarget.disabled = true
    this.transferButtonTarget.disabled = true

    let formData = new FormData();
    if (this.feedbackId) {
      formData.append('memo', this.memoTarget.value);
      formData.append('feedback_id', this.feedbackId);
    } else if (this.callLogId) {
      formData.append('memo', this.memoTarget.value);
      formData.append('call_log_id', this.callLogId);
    }

    Rails.ajax({
      type: 'POST',
      url: '/api/admin_customer_transfers',
      data: formData,
      success: () => {
        alert('送客内容を更新しました');
        location.reload();
      },
      error: (response) => {
        alert(response.error);
        location.reload();
      }
    });
  }

  updateMemo() {
    this.buttonTarget.disabled = true
    this.transferButtonTarget.disabled = true

    let formData = new FormData();
    formData.append('id', this.id);
    formData.append('memo', this.memoTarget.value);

    Rails.ajax({
      type: 'PATCH',
      url: `/api/admin_customer_transfers/${this.customerTransferId}`,
      data: formData,
      success: () => {
        alert('送客内容を更新しました');
        location.reload();
      },
      error: (response) => {
        alert(response.error);
        location.reload();
      }
    });
  }

  transfer() {
    this.buttonTarget.disabled = true
    this.transferButtonTarget.disabled = true

    const isNewRecord = this.transferButtonTarget.dataset.isNewRecord === 'true';

    const result = confirm('本当に送客しますか？');
    if (!result) {
      this.buttonTarget.disabled = false
      this.transferButtonTarget.disabled = false

      return;
    }

    let formData = new FormData();
    if (this.feedbackId) {
      formData.append('memo', this.memoTarget.value);
      formData.append('feedback_id', this.feedbackId);
    } else if (this.callLogId) {
      formData.append('memo', this.memoTarget.value);
      formData.append('call_log_id', this.callLogId);
    }
    formData.append('is_new_record', isNewRecord);

    let url;
      if (isNewRecord) {
        url = '/api/admin_customer_transfers/new/transfer';
      } else {
        url = `/api/admin_customer_transfers/${this.customerTransferId}/transfer`;
     }

    Rails.ajax({
      type: 'PATCH',
      url: url,
      data: formData,
      success: () => {
        alert('送客が完了しました');
        location.reload();
      },
      error: (response) => {
        alert(response.error);
        location.reload();
      }
    });
  }

  get feedbackId() {
    return this.data.get('feedback-id')
  }

  get callLogId() {
    return this.data.get('call-log-id')
  }

  get customerTransferId() {
    return this.data.get('id')
  }
}
