import Rails from 'rails-ujs';
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [ 'checkBoxes', 'allCheckBox' ]

  checkAll(event) {
    this.checkBoxesTargets.forEach(element => {
      element.checked = event.target.checked;
    });
  }

  childCheck(event) {
    if(this.checkBoxesTargets.every(element => !element.checked)) {
      this.allCheckBoxTarget.checked = false;
    }

    // if(this.checkBoxesTargets.some(element => element.checked)) {
    //   this.allCheckBoxTarget.checked = true;
    // }
  }
}
