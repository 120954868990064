/* global google */

import Rails from 'rails-ujs';
import GoogleMap from '../classes/google_map';
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [ 'map', 'lat', 'lng', 'prefecture', 'city', 'block', 'remainAddress' ]

  initialize() {
    GoogleMap.dispatchCallbackEvent();
  }

  connect() {
    this.element['address'] = this;
  }

  initializeMap() {
    this.geocoder = new google.maps.Geocoder();
    // REINSインポート物件用、住所があって緯度経度が無い場合自動的に入れる
    if(this.blockTarget.value && (this.latTarget.value === "" || this.lngTarget.value === "")) {
        this.getLocation();
    }
    const mapOptions = this.mapOptions();
    this.map = new google.maps.Map(this.mapTarget, mapOptions);
    this.marker = new google.maps.Marker({
      map: this.map,
      position: mapOptions.center,
      draggable: true
    });

    google.maps.event.addListener(this.marker, 'dragend', () => {
      this.setMarkerPositionInForm();
    });
  }

  mapOptions() {
    const options = GoogleMap.options();
    options.zoom = 18;
    options.center = {
      lat: Number(this.latTarget.value),
      lng: Number(this.lngTarget.value)
    };
    return options;
  }

  setMarkerPositionInForm() {
    const position = this.marker.getPosition();
    this.latTarget.value = position.lat();
    this.lngTarget.value = position.lng();
  }

  prefectureChanged() {
    if (!this.prefectureTarget.value) {
      this.cityTarget.innerHTML = null;
      this.blockTarget.innerHTML = null;
      return;
    }

    return this.getAddress({ prefecture_id: this.prefectureTarget.value }).then(() => {
      this.updateRailwayPrefectures();
    });
  }

  updateRailwayPrefectures() {
    Array.from(document.querySelectorAll('[data-controller=\'access\']')).forEach(access => {
      const accessController = this.application.getControllerForElementAndIdentifier(
        access, 'access'
      );

      if (accessController.lineTarget.value)
        return;

      accessController.railwayPrefectureTarget.value = this.prefectureTarget.value;
      accessController.railwayPrefectureChanged();
    });
  }

  cityChanged() {
    if (!this.cityTarget.value) {
      this.blockTarget.innerHTML = null;
      return;
    }

    return this.getAddress({ city_id: this.cityTarget.value });
  }

  getAddress(params) {
    let url = '/api/addresses?';
    Object.keys(params).forEach(key => url += `${key}=${params[key]}&`);
    let self = this;
    return new Promise(function(resolve, reject) {
      Rails.ajax({
        type: 'GET',
        dataType: 'json',
        url: url,
        success: (json) => { self.renderAddressSelectOptions(json); resolve(json); },
        error: () => { self.showAlert(); reject(); }
      });
    });
  }

  renderAddressSelectOptions(json) {
    let target, results;

    if (json.cities) {
      target = this.cityTarget;
      results = json.cities;
      this.blockTarget.innerHTML = '';
    } else if (json.blocks) {
      target = this.blockTarget;
      results = json.blocks;
    }

    this.renderSelectOptions(target, results);
  }

  renderSelectOptions(target, results) {
    if (!target || !results)
      return;

    target.innerHTML = '';
    target.add(document.createElement('option'));
    results.forEach(place => {
      const option = document.createElement('option');
      option.value = place.id;
      option.text = place.name;
      target.add(option);
    });
  }

  getLocation() {
    const prefecture = this.prefectureTarget[this.prefectureTarget.selectedIndex].text;
    const city = this.cityTarget[this.cityTarget.selectedIndex].text;
    const block = this.blockTarget[this.blockTarget.selectedIndex].text;
    const remainAddress = this.remainAddressTarget.value;

    if (prefecture && city && block) {
      const address = `${prefecture} ${city} ${block} ${remainAddress}`;
      this.geocoder.geocode({ address: address }, (results, status) => {
        if (status === 'OK') {
          const location = results[0].geometry.location;
          this.marker.setPosition(location);
          this.setMarkerPositionInForm();
          this.map.setCenter(location);
        }
      });
    }
  }

  showAlert() {
    alert('エラーが発生しました。ブラウザをリロードして、再度お試しください。');
  }
}
