import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ "form", "sort", "direction" ]

  sort(event) {
    event.preventDefault();

    this.sortTarget.value = event.currentTarget.dataset.sort;
    this.directionTarget.value = event.currentTarget.dataset.direction;
    this.formTarget.submit();
  }
}
