import Rails from 'rails-ujs';
import { Controller } from 'stimulus';
import async from 'async';

export default class extends Controller {
  static targets = [ 'photoId', 'file', 'filePreview', 'caption', 'destroy' ]

  connect() {
    addEventListener('DOMContentLoaded', () => {
      this.filePreviewTarget.addEventListener('dragenter', (event) => {
        event.stopPropagation();
        event.preventDefault();
      });

      this.filePreviewTarget.addEventListener('dragover', (event) => {
        event.stopPropagation();
        event.preventDefault();
      });

      this.filePreviewTarget.addEventListener('drop', (event) => {
        event.stopPropagation();
        event.preventDefault();
        const file = event.dataTransfer.files[0];
        this.handleFile(file);
      });
    });
  }

  // TODO: data-controller="estate-photo"の定義が
  // ・一括アップロードボタン
  // ・各画像編集の要素
  // で存在している。
  // そのため、各要素毎にcontrollerが生成されており、できれば全体の1つのcontrollerで管理したい。
  // 全体のリファクタリングとなるが、どこかで対応したい。
  getController(index) {
    const estatePhotoControllerElements = document.querySelectorAll('[data-controller="estate-photo"]');
    const estatePhotoControllers = Array.from(estatePhotoControllerElements).map(element => {
      return this.application.getControllerForElementAndIdentifier(element, "estate-photo");
    });
    return estatePhotoControllers[index];
  }

  getImageFieldController(index) {
    // data-controller="estate-photo"の定義が
    // ・一括アップロードボタン
    // ・各画像編集の要素
    // で設定されているため、一括アップロードボタンを考慮したindexを指定している
    return this.getController(Number(index) + 1);
  }

  destroyChanged() {
    if (this.isUploading())
      return;

    this.destroyTarget.value = true;
    this.captionTarget.value = '';
    this.resetPreview(this.filePreviewTarget);
  }

  fileBulkChanged(event){
    const indices = Object.keys(event.target.files);
    if (indices.length > 20) {
      alert('写真は20枚までアップロードできます。');
      return;
    }
    this.disableFileInput();
    const functions = indices.map((index) => {
      return (callback) => {
        let target = document.getElementById(`estate_estate_photos_attributes_${index}_photo`);
        let preview = target.closest('.m-form-image-field').querySelector('.m-file-input__preview');
        let file = event.target.files[index];
        this.handleFile_Bulk(file, preview, index, callback);
      };
    });
    functions.push((callback) => {
      this.enableFileInput();
      callback();
    });
    async.series(functions, () => { });
  }

  handleFile_Bulk(file, preview, index, callback) {
    this.prepareUpload_Bulk(preview, index);
    this.uploadFile_Bulk(file, preview, index, callback);
  }

  prepareUpload_Bulk(preview, index) {
    preview.innerHTML = 'アップロード中…';
  }

  resetPreview(preview) {
    preview.innerHTML = '<i class="fa fa-picture-o"></i><p>画像をここへ<br>ドラッグアンドドロップ</p>';
  }

  resetDestroy(index) {
    const controller = this.getImageFieldController(index);
    if (controller.hasDestroyTarget) {
      controller.destroyTarget.value = false;
    }
  }

  resetCaption(index) {
    const controller = this.getImageFieldController(index);
    if (controller.hasCaptionTarget) {
      controller.captionTarget.value = '';
    }
  }

  uploadFile_Bulk(file, preview, index, callback) {
    const formData = new FormData();
    formData.append('estate_photo[photo]', file);
    const previousHidden = document.getElementById(`estate_estate_photos_attributes_${index}_id`);
    Rails.ajax({
      type: 'POST',
      dataType: 'json',
      data: formData,
      url: '/api/estate_photos',
      success: (json) => {
        // 上書きした画像を削除対象にする
        this.markForDestructionIfIdExists_Bulk(previousHidden);
        this.finishedUploadedingFile_Bulk(file, preview, index, json.id);
        // 一括アップロード前に「削除」ボタンが押されていた場合、一括アップロードした画像が削除対象となってしまうため、falseに戻す
        this.resetDestroy(index);
        this.resetCaption(index);
        callback();
      },
      error: (json) => {
        let message;
        if (json.errors)
          message = json.errors.join(', ');
        this.resetFile();
        this.showAlert(message);
        this.resetPreview(preview);
        this.markForDestructionIfIdExists_Bulk(previousHidden);
        callback(message);
      }
    });
  }

  markForDestructionIfIdExists_Bulk(previousHidden) {
    if (previousHidden.value) {
      const div = document.createElement('div');
      const name = `estate[estate_photos_attributes][${Date.now()}]`;
      const idInput = document.createElement('input');
      idInput.type = 'hidden';
      const destroyInput = idInput.cloneNode(false);
      idInput.name = `${name}[id]`;
      idInput.value = previousHidden.value;
      destroyInput.name = `${name}[_destroy]`;
      destroyInput.value = 1;
      div.appendChild(idInput);
      div.appendChild(destroyInput);
      previousHidden.closest('.m-form-image-field').parentNode.appendChild(div);
    }
  }

  finishedUploadedingFile_Bulk(file, preview, index, photoId) {
    this.previousPhotoId = photoId;
    document.getElementById(`estate_estate_photos_attributes_${index}_id`).value = photoId;
    const reader = new FileReader();
    reader.onload = (e) => {
      const img = document.createElement('img');
      img.classList.add('preview');
      img.src = e.target.result;
      preview.innerHTML = '';
      preview.appendChild(img);
    };
    reader.readAsDataURL(file);
  }

  showAlert(message = null) {
    if (!message)
      message = 'エラーが発生しました。ブラウザをリロードして、再度お試しください。';

    alert(message);
  }

  get fileTypes() {
    return ['jpg', 'jpeg', 'png'];
  }


  fileChanged(event) {
    const file = event.target.files[0];
    this.handleFile(file);
  }

  handleFile(file) {
    if (this.isUploading())
      return;

    if (this.isValid(file)) {
      this.prepareUpload();
      this.uploadFile(file);
    } else {
      this.resetFile();
    }
  }

  isValid(file) {
    if (!file)
      return false;

    if (this.fileTypes.indexOf(file.type.split('/')[1]) < 0) {
      alert('画像ファイル（jpg, png）を選択してください');
      return false;
    }

    if (file.size > 3145728) {
      alert('ファイルサイズは最大3MBです');
      return false;
    }

    return true;
  }

  isUploading() {
    return this.fileTarget.disabled === true;
  }

  enableFileInput() {
    this.fileTarget.disabled = false;
  }

  disableFileInput() {
    this.fileTarget.disabled = true;
  }

  resetFile() {
    this.enableFileInput();
    this.fileTarget.value = '';
  }

  prepareUpload() {
    this.destroyTarget.value = false;
    this.disableFileInput();
    this.filePreviewTarget.innerHTML = 'アップロード中…';
  }

  uploadFile(file) {
    const formData = new FormData();
    formData.append('estate_photo[photo]', file);

    Rails.ajax({
      type: 'POST',
      dataType: 'json',
      data: formData,
      url: '/api/estate_photos',
      success: (json) => {
        this.markForDestructionIfIdExists();
        this.destroyTemporaryFile();
        this.finishedUploadedingFile(json.id, file);
      },
      error: (json) => {
        let message;
        if (json.errors)
          message = json.errors.join(', ');
        this.resetFile();
        this.showAlert(message);
      },
      complete: () => {
        this.enableFileInput();
      }
    });
  }

  markForDestructionIfIdExists() {
    if (this.previousPhotoId || !this.photoIdTarget.value)
      return;

    const div = document.createElement('div');
    const name = `estate[estate_photos_attributes][${Date.now()}]`;
    const idInput = document.createElement('input');
    idInput.type = 'hidden';
    const destroyInput = idInput.cloneNode(false);
    idInput.name = `${name}[id]`;
    idInput.value = this.photoIdTarget.value;
    destroyInput.name = `${name}[_destroy]`;
    destroyInput.value = 1;
    div.appendChild(idInput);
    div.appendChild(destroyInput);
    this.fileTarget.closest('.m-form-image-field').parentNode.appendChild(div);
  }

  destroyTemporaryFile() {
    if (!this.previousPhotoId)
      return;

    Rails.ajax({
      type: 'DELETE',
      dataType: 'json',
      url: `/api/estate_photos/${this.previousPhotoId}`
    });
  }

  finishedUploadedingFile(photoId, file) {
    this.previousPhotoId = photoId;
    this.photoIdTarget.value = photoId;
    this.fileTarget.value = '';

    const reader = new FileReader();
    reader.onload = (e) => {
      const img = document.createElement('img');
      img.classList.add('preview');
      img.src = e.target.result;
      this.filePreviewTarget.innerHTML = '';
      this.filePreviewTarget.appendChild(img);
    };
    reader.readAsDataURL(file);
  }

  showAlert(message = null) {
    if (!message)
      message = 'エラーが発生しました。ブラウザをリロードして、再度お試しください。';

    alert(message);
  }

  get fileTypes() {
    return ['jpg', 'jpeg', 'png'];
  }
}
