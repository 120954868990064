import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'prefectureCheckBoxes', 'cityCheckBoxes', 'Tokyo23CityCheckBoxes', 'TokyoOtherCityCheckBoxes', 'YokohamaCityCheckBoxes', 'KawasakiCityCheckBoxes', 'KanagawaOtherCityCheckBoxes', 'ChibaCityCheckBoxes', 'SaitamaCityCheckBoxes', 'output' ]

  connect() {
    this.checkToPrefectureCheckBox(
      [this.Tokyo23CityCheckBoxesTargets, this.TokyoOtherCityCheckBoxesTargets, this.YokohamaCityCheckBoxesTargets, this.KawasakiCityCheckBoxesTargets, this.KanagawaOtherCityCheckBoxesTargets, this.ChibaCityCheckBoxesTargets, this.SaitamaCityCheckBoxesTargets],
      ['condition_Tokyo23', 'condition_TokyoOther', 'condition_Yokohama', 'condition_Kawasaki', 'condition_KanagawaOther', 'condition_Chiba', 'condition_Saitama']
    )
    this.countCheck()
  }

  checkToPrefectureCheckBox(cityCheckBoxTargets, prefectureIds) {
    cityCheckBoxTargets.forEach((cityCheckBoxTarget, index) => {
      let count = 0
      cityCheckBoxTarget.forEach(element => {
        if (element.checked) {
          count++
        }
      })
      if (cityCheckBoxTarget.length === count) {
        document.getElementById(prefectureIds[index]).checked = true
      }
    })
  }

  countCheck(){
    this.count = 0
    this.cityCheckBoxesTargets.forEach(element => {
      if (element.checked){
        this.count++
      }
    })
    this.outputTarget.textContent = this.count
  }

  countCheckAll(checked, cityCheckBoxesTargets) {
    let checkAllCount = 0
    if (checked) {
      cityCheckBoxesTargets.forEach(element => {
        if (!element.checked) {
          checkAllCount++
        }
      })
      this.count = this.count + checkAllCount
    } else {
      cityCheckBoxesTargets.forEach(element => {
        if (element.checked) {
          checkAllCount++
        }
      })
      this.count = this.count - checkAllCount
    }
    this.outputTarget.textContent = this.count
  }

  onCheck() {
    this.countCheck()
  }

  onCheckAll(e) {
    const target = e.currentTarget
    switch (target.name) {
    case 'condition[Tokyo23]':
      this.countCheckAll(target.checked, this.Tokyo23CityCheckBoxesTargets)
      break
    case 'condition[TokyoOther]':
      this.countCheckAll(target.checked, this.TokyoOtherCityCheckBoxesTargets)
      break
    case 'condition[Yokohama]':
      this.countCheckAll(target.checked, this.YokohamaCityCheckBoxesTargets)
      break
    case 'condition[Kawasaki]':
      this.countCheckAll(target.checked, this.KawasakiCityCheckBoxesTargets)
      break
    case 'condition[KanagawaOther]':
      this.countCheckAll(target.checked, this.KanagawaOtherCityCheckBoxesTargets)
      break
    case 'condition[Chiba]':
      this.countCheckAll(target.checked, this.ChibaCityCheckBoxesTargets)
      break
    case 'condition[Saitama]':
      this.countCheckAll(target.checked, this.SaitamaCityCheckBoxesTargets)
      break
    }
  }

  removeCheckAll() {
    this.prefectureCheckBoxesTargets.forEach(element => {
      element.checked = false
    })
    this.cityCheckBoxesTargets.forEach(element => {
      element.checked = false
    })
    this.countCheck()
  }
}
