import Rails from 'rails-ujs';
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [ 'statusForm', 'selectBoxStatus', 'statusFromButton', 'statusFailureReason' ]

  statusUpdate() {
    if (this.statusFromButtonTarget.classList.contains('sending')) {
      return false
    }
    if (this.selectBoxStatusTarget.value == 'failure') {
      document.getElementById('#modal-feedback-status-form-' + this.feedbackId).click()
    } else {
      Rails.fire(this.statusFormTarget, 'submit')
      this.statusFromButtonTarget.style.opacity = 0.7
      this.statusFromButtonTarget.classList.add('sending')
    }
  }

  statusFailureUpdate() {
    const requestData = {
      'failure_reason': this.statusFailureReasonTarget.value
    }
    this.updateFailureStatus(requestData)
  }

  statusFailureSkipUpdate() {
    const requestData = {
      'failure_reason': null
    }
    this.updateFailureStatus(requestData)
  }

  updateFailureStatus(requestData) {
    Rails.ajax({
      type: 'PATCH',
      dataType: 'json',
      url: this.feedbackStatusFailerUpdateUrl,
      data: JSON.stringify(requestData),
      success: (json) => {
        alert('反響ステータスを更新しました');
        location.reload()
      },
      error: () => {
        alert('エラーが発生しました。ブラウザをリロードして、再度お試しください。')
      }
    })
  }

  get feedbackId() {
    return this.data.get('id')
  }

  get feedbackStatusFailerUpdateUrl() {
    return this.data.get('status-failer-update-url')
  }
}
