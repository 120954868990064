import Rails from 'rails-ujs';
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [ 'prefecture', 'city', 'submitButton' ]

  prefectureChanged() {
    this.cityTarget.innerHTML = '';
    if (this.prefectureTarget.value === "") {
        this.renderSelectOptions(this.cityTarget, [])
    } else {
        this.getAddress({ prefecture_id: this.prefectureTarget.value });
    }
    if (this.hasSubmitButtonTarget) {
        this.submitButtonTarget.disabled = this.prefectureTarget.value === "";
    }
  }

  getAddress(params) {
    let url = '/api/cities?';
    Object.keys(params).forEach(key => url += `${key}=${params[key]}&`);

    Rails.ajax({
      type: 'GET',
      dataType: 'json',
      url: url,
      success: (json) => { this.renderSelectOptions(this.cityTarget, json.cities); },
      error: () => { this.showAlert(); }
    });
  }

  renderSelectOptions(target, results) {
    if (!results)
      return;

    const option = document.createElement('option');
    option.text = "市区町村";
    option.value = "";
    target.add(option);
    results.forEach(place => {
      const option = document.createElement('option');
      option.value = place.id;
      option.text = place.name;
      target.add(option);
    });
  }

  showAlert() {
    alert('エラーが発生しました。ブラウザをリロードして、再度お試しください。');
  }
}
