import Rails from 'rails-ujs';
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [ "favoritesSize" ];

  connect() {
    this.element["headerFavorite"] = this;
    this.fetchFavorites();
  }

  fetchFavorites() {
    Rails.ajax({
      type: 'GET',
      dataType: 'json',
      url: `/api/favorites`,
      success: (response) => {
        this.favoritesSizeTarget.textContent = `${response.total_count}件`;
      },
      error: () => {
        this.favoritesSizeTarget.textContent = "− 件";
      },
      complete: () => {}
    });
  }
}
