import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    this.set_events();
  }

  /*
   * イベント設定
   */
  set_events() {
    $(document).ready(function() {
      // サブミット中の多重送信を止める
      $('#onlook-request-cancel').on('confirm:complete', function(event) {
        if (event.detail[0]) {
          $('#onlook-request-update').addClass('link_disabled');
          $('#onlook-request-cancel').addClass('link_disabled');
        }
      });
    });
  }
}
