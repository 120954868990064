import Rails from 'rails-ujs';
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'documentId',
    'label',
    'file',
    'fileName',
    'selectField',
    'textField',
    'toggle',
    'sort',
    'documentUploaded',
    'radioItem',
  ];

  connect() {
    this.syncFieldDisplayWithInputValue();
  }

  handleCheckboxToggle(event) {
    this.updateDocumentSortCount(event);

    const selectElement = this.selectFieldTarget.querySelector('select');
    selectElement.value = '';

    // チェックボックスの状態を取得し、その状態に基づいてフィールドの表示を切り替える
    const isChecked = this.toggleTarget.checked;
    const labelElement = this.toggleTarget.closest('.checkbox-label');

    if (isChecked) {
      this.selectFieldTarget.hidden = true;
      this.textFieldTarget.hidden = false;
      labelElement.classList.add('is-checked');
    } else {
      const inputElement = this.textFieldTarget.querySelector('input');
      inputElement.value = '';

      this.selectFieldTarget.hidden = false;
      this.textFieldTarget.hidden = true;
      labelElement.classList.remove('is-checked');
    }
  }

  handleSelectChange(event) {
    this.updateDocumentSortCount(event);
    const inputElement = this.textFieldTarget.querySelector('input');
    inputElement.value = '';
  }

  handleInputChange(event) {
    this.updateDocumentSortCount(event);
    const selectElement = this.selectFieldTarget.querySelector('select');
    selectElement.value = '';
  }

  handleRadioChange(event) {
    this.radioItemTargets.forEach((item) => {
      const label = item.querySelector('.m-radio__item-radio');
      if (label) {
        label.classList.remove('is-checked');
      }
    });

    const selectedLabel = event.target.closest('label');
    if (selectedLabel) {
      selectedLabel.classList.add('is-checked');
    }
  }

  updateDocumentSortCount(event) {
    const documentSortItems = document.querySelectorAll('.document-sort');
    const eventTarget = event.target.closest('.document-sort');

    let targetIndex = -1;
    documentSortItems.forEach((item, index) => {
      if (item === eventTarget) {
        targetIndex = index;
      }
    });

    if (targetIndex !== -1) {
      this.sortTarget.value = targetIndex + 1;
    }
  }

  syncFieldDisplayWithInputValue() {
    // テキストフィールドの入力値が存在する場合、テキストフィールドを表示し、チェックボックスをチェック状態にする
    const nameInput = this.textFieldTarget.querySelector('input');
    if (nameInput && nameInput.value) {
      this.selectFieldTarget.hidden = true;
      this.textFieldTarget.hidden = false;
      this.toggleTarget.checked = true;
    } else {
      this.selectFieldTarget.hidden = false;
      this.textFieldTarget.hidden = true;
      this.toggleTarget.checked = false;
    }
  }

  fileChanged(event) {
    this.updateDocumentSortCount(event);
    const file = event.target.files[0];

    const toggleChecked = this.toggleTarget.checked;
    const selectElement = this.selectFieldTarget.querySelector('select');
    const inputElement = this.textFieldTarget.querySelector('input');
    if (toggleChecked) {
      if (!inputElement.value) {
        alert('資料名を入力してください。');
        this.resetFile();

        return;
      }
    } else {
      if (!selectElement.value) {
        alert('資料の種類を選択するか、資料名を入力してください。');
        this.resetFile();

        return;
      }
    }
    this.handleFile(file);
  }

  handleFile(file) {
    if (this.isValid(file)) {
      this.prepareUpload();
      this.uploadFile(file);
    } else {
      this.resetFile();
    }
  }

  isValid(file) {
    if (!file)
      return false;

    const type = file.type.split('/').pop();

    if (this.isIE()) {
      if (!this.hasPdfExtensionForIE(type, file.name)) {
        alert('PDFファイルを選択してください');
        return false;
      }
    } else if (this.fileTypes.indexOf(type) < 0) {
      alert('PDFファイルを選択してください');
      return false;
    }

    if (file.size > 30 * 1024 * 1024) {
      alert('ファイルサイズは最大30MBです');
      return false;
    }

    return true;
  }

  isIE() {
    const ua = window.navigator.userAgent;

    const trident = ua.indexOf('Trident/');
    if (trident > 0)
      return true;

    const edge = ua.indexOf('Edge/');
    if (edge > 0)
      return true;

    return false;
  }

  hasPdfExtensionForIE(fileType, fileName) {
    if (fileType !== '')
      return false;

    const fileExtension = fileName.split('.').pop();
    if (this.fileTypes.indexOf(fileExtension) < 0)
      return false;

    return true;
  }

  isUploading() {
    return this.fileTarget.disabled === true;
  }

  enableFileInput() {
    this.fileTarget.disabled = false;
  }

  disableFileInput() {
    this.fileTarget.disabled = true;
  }

  resetFile() {
    this.enableFileInput();
    this.fileNameTarget.textContent = '';
    this.fileTarget.value = '';
  }

  prepareUpload() {
    this.disableFileInput();
    this.fileNameTarget.textContent = 'アップロード中…';
    this.labelTarget.textContent = 'ファイルを変更';
    this.labelTarget.parentNode.classList.add('is-button-simple-sm-secondary');
    this.labelTarget.parentNode.classList.remove('is-button-simple-sm-primary');
  }

  uploadFile(file) {
    const formData = new FormData();
    formData.append('estate_document[document]', file);
    formData.append('estate_document[sort]', this.sortTarget.value);
    const selectElement = this.selectFieldTarget.querySelector('select');
    formData.append('estate_document[kind]', selectElement.value);
    const inputElement = this.textFieldTarget.querySelector('input');
    formData.append('estate_document[name]', inputElement.value);

    Rails.ajax({
      type: 'POST',
      dataType: 'json',
      data: formData,
      url: '/api/estate_documents',
      success: (json) => {
        this.markForDestructionIfIdExists();
        this.destroyTemporaryFile();
        this.finishedUploadedingFile(json.id, file);
        this.documentUploadedTarget.value = 'true';
      },
      error: (json) => {
        let message;
        if (json.errors)
          message = json.errors.join(', ');
        this.resetFile();
        this.showAlert(message);
      },
      complete: () => {
        this.enableFileInput();
      }
    });
  }

  markForDestructionIfIdExists() {
    if (this.previousDocumentId || !this.documentIdTarget.value)
      return;

    const div = document.createElement('div');
    const name = `estate[estate_documents_attributes][${Date.now()}]`;
    const idInput = document.createElement('input');
    idInput.type = 'hidden';
    const destroyInput = idInput.cloneNode(false);
    idInput.name = `${name}[id]`;
    idInput.value = this.documentIdTarget.value;
    destroyInput.name = `${name}[_destroy]`;
    destroyInput.value = 1;
    div.appendChild(idInput);
    div.appendChild(destroyInput);
    this.fileTarget.closest('.m-form-item').parentNode.appendChild(div);
  }

  destroyTemporaryFile() {
    if (!this.previousDocumentId)
      return;

    Rails.ajax({
      type: 'DELETE',
      dataType: 'json',
      url: `/api/estate_documents/${this.previousDocumentId}`
    });
  }

  finishedUploadedingFile(documentId, file) {
    this.previousDocumentId = documentId;
    this.documentIdTarget.value = documentId;
    this.fileTarget.value = '';
    this.fileNameTarget.textContent = file.name;
  }

  showAlert(message = null) {
    if (!message)
      message = 'エラーが発生しました。ブラウザをリロードして、再度お試しください。';

    alert(message);
  }

  get fileTypes() {
    return ['pdf'];
  }
}
