import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.modalElement = document.getElementById('client-estate-analysis');

    const footprintPriceData = document.getElementById('footprint-price-data');
    if (footprintPriceData) {
      const jsonString = footprintPriceData.getAttribute('data-json');
      const unescapedString = jsonString.replace(/\\"/g, '"');
      const jsonData = JSON.parse(unescapedString);

      if (Object.keys(jsonData.footprint_price_data).length > 0) {
        this.drawScatterPlot(jsonData.footprint_price_data);
      }
    }

    const sellingCountFootprintDataElement = document.getElementById('selling-count-footprint-data');
    if (sellingCountFootprintDataElement) {
      const jsonString = sellingCountFootprintDataElement.getAttribute('data-json');
      const unescapedString = jsonString.replace(/\\"/g, '"');
      const jsonData = JSON.parse(unescapedString);

      if (Object.keys(jsonData.selling_count_transition_data).length > 0) {
        this.drawBarChart(jsonData.selling_count_transition_data);
      }
    }
  }

  showReport(event) {
    event.preventDefault();

    const modal = new bootstrap.Modal(this.modalElement);
    modal.show();
  }

  getSearchText() {
    const analysisLabelDataElement = document.getElementById('analysis-label-data');
    const jsonString = analysisLabelDataElement.getAttribute('data-json');
    const unescapedString = jsonString.replace(/\\"/g, '"');
    const jsonData = JSON.parse(unescapedString);

    return jsonData.analysis_label && jsonData.analysis_label[0] ? jsonData.analysis_label[0].text : '';
  }

  drawScatterPlot(jsonData) {
    let ctx = document.getElementById('canvas').getContext('2d');
    const SearchText = this.getSearchText()
    let scatter_data = {
      datasets:[{
        label: `${SearchText}の価格と専有面積の散布図`,
        backgroundColor: '#00788D',
        pointRadius: 5,
        data: jsonData.filter(item => item.x <= 200 && item.y <= 20000) // suggestedMaxに合わせてxとyの両方にフィルタを適用
      }]
    };

    new Chart(ctx, {
      type: 'scatter',
      data: scatter_data,
      options: {
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            scaleLabel: {
              display: true,
              labelString: '専有面積',
            },
            ticks: {
              suggestedMin: 0,
              suggestedMax: 200,
              stepSize: 20,
              callback: function (value) {
                return value + '㎡';
              }
            },
          }],
          yAxes: [{
            scaleLabel: {
              display: true,
              labelString: '価格(万円)',
            },
            ticks: {
              suggestedMin: 0,
              suggestedMax: 20000,
              stepSize: 2000,
              callback: function (value) {
                return value.toLocaleString() + '万円';
              }
            },
          }],
        },
        tooltips: {
          callbacks: {
            label: function (tooltipItem) {
              let xLabel = tooltipItem.xLabel + '㎡';
              let yLabel = tooltipItem.yLabel.toLocaleString() + '万円';
              return `${yLabel}, ${xLabel}`;
            }
          }
        }
      }
    });
  }

  drawBarChart(jsonData) {
    let ctx = document.getElementById('barChart').getContext('2d');

    jsonData.sort((a, b) => new Date(a.date) - new Date(b.date));
    let xlabels = jsonData.map(entry => entry.date);
    let dataValues = jsonData.map(entry => entry.count);

    const SearchText = this.getSearchText()
    let dataset = {
      label: `${SearchText}の売出件数の推移`,
      data: dataValues,
      backgroundColor: '#00788D',
      borderColor: '#00788D',
      borderWidth: 1,
    };

    new Chart(ctx, {
      type: 'bar',
      data: {
        labels: xlabels,
        datasets: [dataset],
      },
      options: {
        scales: {
          yAxes: [{
            scaleLabel: {
              display: true,
              labelString: '売出件数',
            },
            ticks: {
              suggestedMin: 0,
              suggestedMax: 100,
              stepSize: 10,
            },
          }],
        },
        tooltips: {
          callbacks: {
            label: function (tooltipItem) {
              return `${tooltipItem.yLabel}件`;
            }
          }
        },
      },
    });
  }
}
