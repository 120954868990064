/* eslint no-console:0 */
/* global require */

import '@babel/polyfill';
import 'core-js';
import 'element-closest';
import Rails from 'rails-ujs';
import { Application } from 'stimulus';
import { Autocomplete } from 'stimulus-autocomplete';
import { definitionsFromContext } from 'stimulus/webpack-helpers';
import 'jquery';
import 'jquery.cookie';
import 'jquery-ui';
import 'jquery-ui/ui/widgets/slider';
import 'jquery-ui/ui/widgets/mouse';
import 'jquery-validation';
import 'jquery-ui-touch-punch';
import 'datepicker';
import 'lazysizes';
import 'script';
import ReactRailsUJS from 'react_ujs';

require('jquery');
require('jquery-ui');
require('jquery-ui-touch-punch');
require('jquery-ui/themes/base/all.css');

Rails.start();
const application = Application.start();
const context = require.context('controllers', true, /.js$/);
application.load(definitionsFromContext(context));
application.register('autocomplete', Autocomplete);
ReactRailsUJS.useContext(require.context('components', true));
