import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['notification', 'optout'];

  toggleNotification() {
    if (this.notificationTarget.checked) {
      this.optoutTarget.checked = false;
    }
  }

  toggleOptout() {
    if (this.optoutTarget.checked) {
      this.notificationTarget.checked = false;
    }
  }
}
