import Rails from 'rails-ujs';
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ["favoriteImage"];
  connect() {
    this.updatingFavorite = false;
    this.favoriteImageTarget.src = this.isFavorited ?  this.starWhiteImage : this.starImage;
  }

  toggleHtml() {
    this.data.set('favorited', this.isFavorited ? 'false' : 'true');
    this.element.classList.toggle('active');
    this.favoriteImageTarget.src = this.isFavorited ? this.starWhiteImage : this.starImage;
  }

  toggleState() {
    this.updatingFavorite = !this.updatingFavorite;
  }

  setFavoriteControllers() {
    this.favoriteControllers = [];
    Array.from(document.querySelectorAll('[data-controller=\'favorite\']')).forEach(favorite => {
      if (this.estateId === favorite.dataset.favoriteEstateId) {
        const controller = this.application.getControllerForElementAndIdentifier(
          favorite, 'favorite'
        );
        this.favoriteControllers.push(controller);
      }
    });
  }

  post(event) {
    event.stopPropagation();
    event.preventDefault();

    if(this.confirmMessage && this.isFavorited && !confirm(this.confirmMessage)) return;

    if (this.favoriteControllers === undefined)
      this.setFavoriteControllers();

    if (this.updatingFavorite)
      return;

    this.favoriteControllers.forEach(controller => {
      controller.toggleHtml();
      controller.toggleState();
    });

    Rails.ajax({
      type: this.isFavorited ? 'POST' : 'DELETE',
      dataType: 'json',
      url: `/api/estates/${this.estateId}/favorite`,
      success: () => {
        this.hideEstate();
      },
      error: () => this.showAlert(),
      complete: () => {
        const foundHeaders = document.querySelectorAll("[data-controller='header-favorite']");
        if(foundHeaders.length) {
          foundHeaders[0].headerFavorite.fetchFavorites();
        }

        this.favoriteControllers.forEach(controller => controller.toggleState());
      }
    });
  }

  hideEstate() {
    if (this.pageTitle === '最近見た物件' && this.isFavorited === false) {
      if (document.getElementById(this.estateId)) {
        document.getElementById(this.estateId).style.display = 'none';
      }
    } else if (this.pageTitle === 'お気に入り登録物件') {
      if (this.data.get('hideEstate') === 'true')
        this.element.closest('li').style.display = 'none';
    }
  }

  redirectToSingIn(e) {
    e.preventDefault();
    location.href = '/user/sign_in';
  }

  showAlert() {
    alert('エラーが発生しました。ブラウザをリロードして、再度お試しください。');
  }

  get pageTitle() {
    return this.data.get('page-title');
  }

  get estateId() {
    return this.data.get('estateId');
  }

  get isFavorited() {
    return this.data.get('favorited') === 'true';
  }

  get confirmMessage() {
    return this.data.get('confirm-massage');
  }

  get starImage() {
    return this.data.get('star-image');
  }

  get starWhiteImage() {
    return this.data.get('star-white-image');
  }
}
