import { Controller } from 'stimulus';
import Rails from 'rails-ujs';

export default class extends Controller {
  static targets = [
    'checkBox',
    'checkBoxRequieAvailable',
    'checkBoxFeesFreeDisplay',
    'checkBoxIsFlieAgent',
    'checkBoxCanSelectListing',
    'checkBoxBukkakuEnabled',
    'checkBoxReinsImportEnabled',
    'checkBoxNotifyAnnouncementEnabled',
  ]

  onCheckNotifyAnnouncementEnabled() {
    if (this.checkBoxNotifyAnnouncementEnabledTarget.checked) {
      if (confirm('お知らせ通知を許可しますか？')) {
        this.updateNotifyAnnouncementEnabled();
      } else {
        this.checkBoxNotifyAnnouncementEnabledTarget.checked = false;
      }
    } else {
      if (confirm('お知らせ通知を停止しますか？')) {
        this.updateNotifyAnnouncementEnabled();
      } else {
        this.checkBoxNotifyAnnouncementEnabledTarget.checked = true;
      }
    }
  }

  updateNotifyAnnouncementEnabled() {
    Rails.ajax({
      type: 'PATCH',
      dataType: 'json',
      url: `/api/admin_realtors/${this.checkBoxNotifyAnnouncementEnabledTarget.defaultValue}/update_notify_announcement?notify_announcement=${this.checkBoxNotifyAnnouncementEnabledTarget.checked}`,
      success: (json) => {
        if (json.notify_announcement) {
          alert('お知らせ通知を許可しました。');
        } else {
          alert('お知らせ通知を停止しました。');
        }
      },
      error: () => {
        alert('エラーが発生しました。ブラウザをリロードして、再度お試しください。')
      }
    });
  }

  onCheckReinsImportEnabled() {
    if (this.checkBoxReinsImportEnabledTarget.checked) {
      if (confirm('新REINSインポートを許可しますか？')) {
        this.updateReinsImportEnabled()
      } else {
        this.checkBoxReinsImportEnabledTarget.checked = false
      }
    } else {
      if (confirm('新REINSインポートを停止しますか？')) {
        this.updateReinsImportEnabled()
      } else {
        this.checkBoxReinsImportEnabledTarget.checked = true
      }
    }
  }

  updateReinsImportEnabled() {
    Rails.ajax({
      type: 'PATCH',
      dataType: 'json',
      url: `/api/admin_realtors/${this.checkBoxReinsImportEnabledTarget.defaultValue}/update_reins_import_enabled?reins_import_enabled=${this.checkBoxReinsImportEnabledTarget.checked}`,
      success: (json) => {
        if (json.reins_import_enabled) {
          alert('新REINSインポートを許可しました')
        } else {
          alert('新REINSインポートを停止しました')
        }
      },
      error: () => {
        alert('エラーが発生しました。ブラウザをリロードして、再度お試しください。')
      }
    })
  }

  onCheckBukkakuEnabled() {
    if (this.checkBoxBukkakuEnabledTarget.checked) {
      if (confirm('物確の利用を許可しますか？')) {
        this.updateBukkakuEnabled()
      } else {
        this.checkBoxBukkakuEnabledTarget.checked = false
      }
    } else {
      if (confirm('物確の利用を停止しますか？')) {
        this.updateBukkakuEnabled()
      } else {
        this.checkBoxBukkakuEnabledTarget.checked = true
      }
    }
  }

  updateBukkakuEnabled() {
    Rails.ajax({
      type: 'PATCH',
      dataType: 'json',
      url: `/api/admin_realtors/${this.checkBoxBukkakuEnabledTarget.defaultValue}/update_bukkaku_enabled?bukkaku_enabled=${this.checkBoxBukkakuEnabledTarget.checked}`,
      success: (json) => {
        if (json.bukkaku_enabled) {
          alert('物確の利用を許可しました')
        } else {
          alert('物確の利用を停止しました')
        }
      },
      error: () => {
        alert('エラーが発生しました。ブラウザをリロードして、再度お試しください。')
      }
    })
  }

  onCheckCanSelectListing() {
    if (this.checkBoxCanSelectListingTarget.checked) {
      if (confirm('FLIE物件掲載の選択権限を有効にしますか？')) {
        this.updateCanSelectListing()
      } else {
        this.checkBoxCanSelectListingTarget.checked = false
      }
    } else {
      if (confirm('FLIE物件掲載の選択権限を無効にしますか？')) {
        this.updateCanSelectListing()
      } else {
        this.checkBoxCanSelectListingTarget.checked = true
      }
    }
  }

  updateCanSelectListing() {
    Rails.ajax({
      type: 'PATCH',
      dataType: 'json',
      url: `/api/admin_realtors/${ this.checkBoxCanSelectListingTarget.defaultValue }/update_can_select_listing?can_select_listing=${ this.checkBoxCanSelectListingTarget.checked }`,
      success: (json) => {
        if (json.can_select_listing) {
          alert('FLIE物件掲載の選択権限を有効にしました')
        } else {
          alert('FLIE物件掲載の選択権限を無効にしました')
        }
      },
      error: () => {
        alert('エラーが発生しました。ブラウザをリロードして、再度お試しください。')
      }
    })
  }

  onCheckIsFlieAgent() {
    if (this.checkBoxIsFlieAgentTarget.checked){
      if (confirm('対応窓口FLIEエージェントにしますか？')){
        this.updateIsFlieAgent()
      } else {
        this.checkBoxIsFlieAgentTarget.checked = false
      }
    } else {
      if (confirm('対応窓口FLIEエージェントを解除しますか？')){
        this.updateIsFlieAgent()
      } else {
        this.checkBoxIsFlieAgentTarget.checked = true
      }
    }
  }

  updateIsFlieAgent(){
    Rails.ajax({
      type: 'PATCH',
      dataType: 'json',
      url: `/api/admin_realtors/${ this.checkBoxIsFlieAgentTarget.defaultValue }/update_is_flie_agent?is_flie_agent=${ this.checkBoxIsFlieAgentTarget.checked }`,
      success: (json) => {
        if (json.is_flie_agent) {
          alert('対応窓口FLIEエージェントにしました')
        } else {
          alert('対応窓口FLIEエージェントを解除しました')
        }
      },
      error: () => {
        alert('エラーが発生しました。ブラウザをリロードして、再度お試しください。')
      }
    })
  }

  onCheck() {
    if (this.checkBoxTarget.checked){
      if (confirm('プロフィールを公開してよろしいですか？')){
        this.upDatePublishProfile()
      } else {
        this.checkBoxTarget.checked = false
      }
    } else {
      if (confirm('プロフィールを非公開にしてよろしいですか？')){
        this.upDatePublishProfile()
      } else {
        this.checkBoxTarget.checked = true
      }
    }
  }

  upDatePublishProfile(){
    Rails.ajax({
      type: 'PATCH',
      dataType: 'json',
      url: `/api/admin_realtors/${ this.checkBoxTarget.defaultValue }/update_publish_profile?publish_profile=${ this.checkBoxTarget.checked }`,
      success: (json) => {
        if (json.publish_profile) {
          alert('プロフィールを公開しました')
        } else {
          alert('プロフィールを非公開にしました')
        }
      },
      error: () => {
        alert('エラーが発生しました。ブラウザをリロードして、再度お試しください。')
      }
    })
  }

  onCheckRequieAvailable() {
    if (this.checkBoxRequieAvailableTarget.checked){
      if (confirm('りくいえの利用を許可しますか？')){
        this.updateRequieAvailable()
      } else {
        this.checkBoxRequieAvailableTarget.checked = false
      }
    } else {
      if (confirm('りくいえの利用を停止しますか？')){
        this.updateRequieAvailable()
      } else {
        this.checkBoxRequieAvailableTarget.checked = true
      }
    }
  }

  updateRequieAvailable(){
    Rails.ajax({
      type: 'PATCH',
      dataType: 'json',
      url: `/api/admin_realtors/${ this.checkBoxRequieAvailableTarget.defaultValue }/update_requie_available?requie_available=${ this.checkBoxRequieAvailableTarget.checked }`,
      success: (json) => {
        if (json.requie_available) {
          alert('りくいえの利用を許可しました')
        } else {
          alert('りくいえの利用を停止しました')
        }
      },
      error: () => {
        alert('エラーが発生しました。ブラウザをリロードして、再度お試しください。')
      }
    })
  }

  onCheckFeesFreeDisplay() {
    if (this.checkBoxFeesFreeDisplayTarget.checked){
      if (confirm('手数料無料表記を表示しますか？')){
        this.updateFeesFreeDisplay()
      } else {
        this.checkBoxFeesFreeDisplayTarget.checked = false
      }
    } else {
      if (confirm('手数料無料表記を非表示にしますか？')){
        this.updateFeesFreeDisplay()
      } else {
        this.checkBoxFeesFreeDisplayTarget.checked = true
      }
    }
  }

  updateFeesFreeDisplay(){
    Rails.ajax({
      type: 'PATCH',
      dataType: 'json',
      url: `/api/admin_realtors/${ this.checkBoxFeesFreeDisplayTarget.defaultValue }/update_fees_free_display?fees_free_display=${ this.checkBoxFeesFreeDisplayTarget.checked }`,
      success: (json) => {
        if (json.fees_free_display) {
          alert('手数料無料表記を表示しました')
        } else {
          alert('手数料無料表記を非表示にしました')
        }
      },
      error: () => {
        alert('エラーが発生しました。ブラウザをリロードして、再度お試しください。')
      }
    })
  }
}
