import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['prefectureList', 'hashTagLink', 'radioButton'];

  connect() {
    this.updatePrefectureListOnLoad()
  }

  updatePrefectureListOnLoad() {
    const selectedRadioButton = this.radioButtonTargets.find(radio => radio.checked);
    if (selectedRadioButton) {
      this.updatePrefectureList({ target: selectedRadioButton });
      this.updateHashTagLinks(selectedRadioButton.value);
    }
  }

  updateHashTagLinks(selectedAreaId) {
    this.hashTagLinkTargets.forEach((link) => {
      const url = new URL(link.href);
      url.searchParams.set('area_id', selectedAreaId);
      link.href = url.toString();
    });
  }

  updatePrefectureList(event) {
    const selectedAreaId = event.target.value;
    this.updateHashTagLinks(selectedAreaId);

    let prefectures = '';
    this.prefectureListTarget.classList.remove('visible');

    setTimeout(() => {
      switch (selectedAreaId) {
        case '0':
          prefectures = '東京 | 神奈川 | 千葉 | 埼玉';
          this.defaultRadioButtonSelected()
          break;
        case '1':
          prefectures = '北海道 | 青森 | 岩手 | 宮城 | 秋田 | 山形 | 福島';
          break;
        case '2':
          prefectures = '茨城 | 栃木 | 群馬 | 山梨 | 長野';
          break;
        case '4':
          prefectures = '新潟 | 富山 | 石川 | 福井 | 岐阜 | 静岡 | 愛知 | 三重';
          break;
        case '5':
          prefectures = '滋賀 | 京都 | 大阪 | 兵庫 | 奈良 | 和歌山';
          break;
        case '6':
          prefectures = '鳥取 | 島根 | 岡山 | 広島 | 山口 | 徳島 | 香川 | 愛媛 | 高知';
          break;
        case '7':
          prefectures = '福岡 | 佐賀 | 長崎 | 熊本 | 大分 | 宮崎 | 鹿児島 | 沖縄';
          break;
        default:
          prefectures = 'エリアを選択してください';
      }
      this.prefectureListTarget.textContent = prefectures;
      this.prefectureListTarget.classList.add('visible');
    }, 100);
  }

  defaultRadioButtonSelected() {
    const defaultRadioButton = this.radioButtonTargets.find(radio => radio.value === '0');
    defaultRadioButton.checked = true;
  }
}
