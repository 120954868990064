/* eslint-disable */
import Rails from 'rails-ujs';
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['file', , 'estateName', 'filePreview', 'renovation', 'renovationInput', 'submitButton', 'errorContent', 'mainPhoto', 'mainPhotoCaption', 'roomPlanPhoto', 'roomPlanPhotoCaption', 'mainVideo', 'mainVideoCaption', 'repairFundTotal', 'repairFundTotalInput', 'repairFundNonPayment', 'repairFundNonPaymentInput', 'managementFeeNonPayment', 'managementFeeNonPaymentInput', 'managementAssociationDebit', 'managementAssociationDebitInput']

  connect() {
    addEventListener('autocomplete.change', this.autocomplete.bind(this))
    addEventListener('DOMContentLoaded', () => {
      this.filePreviewTargets.forEach(preview => {
        preview.addEventListener('dragenter', (event) => {
          event.stopPropagation();
          event.preventDefault();
        });

        preview.addEventListener('dragover', (event) => {
          event.stopPropagation();
          event.preventDefault();
        });

        preview.addEventListener('drop', (event) => {
          event.stopPropagation();
          event.preventDefault();
          const file = event.dataTransfer.files[0];
          this.handleFile(file, preview, 'dropped');
        });
      });

      const renovationValue = this.renovationTarget.querySelector('input:checked').value;
      this.toggleRenovationInputs(renovationValue);

      if (this.repairFundTotalInputTarget.defaultValue !== ''){
        this.toggleFundTotalInput('repair_fund_total_status_present')
      } else {
        this.toggleFundTotalInput(this.repairFundTotalTarget.querySelector('input:checked').value)
      }

      if (this.repairFundNonPaymentInputTarget.defaultValue !== ''){
        this.toggleRepairFundNonPayment('repair_fund_non_payment_status_present')
      } else {
        this.toggleRepairFundNonPayment(this.repairFundNonPaymentTarget.querySelector('input:checked').value)
      }

      this.managementFeeNonPaymentInputTarget

      if (this.managementFeeNonPaymentInputTarget.defaultValue !== ''){
        this.togglemanagementFeeNonPayment('management_fee_non_payment_status_present')
      } else {
        this.togglemanagementFeeNonPayment(this.managementFeeNonPaymentTarget.querySelector('input:checked').value)
      }

      if (this.managementAssociationDebitInputTarget.defaultValue !== ''){
        this.togglemanagementAssociationDebit('management_association_debit_status_present')
      } else {
        this.togglemanagementAssociationDebit(this.managementAssociationDebitTarget.querySelector('input:checked').value)
      }
    });
    this.estateNameTarget.addEventListener('blur', () => {
      this.validateEstateName(this.estateNameTarget.value);
    });
  }

  validateEstateName(estateName) {
    const reg = /[0-9０-９]{3,}|号室/giu;
    if (reg.test(estateName)) {
      window.alert('「マンション名」には部屋番号を入力しないでください。\n（部屋番号が含まれていない場合はこのメッセージを無視してください。）');
    }
  }

  autocomplete(event) {
    Rails.ajax({
      type: 'GET',
      dataType: 'json',
      url: '/api/estate_suggests/' + event.detail.textValue,
      success: (json) => {
        const estateData = json

        // 住所
        const addressController = this.element.address
        $("[name='estate[prefecture_id]']").val(estateData.estate_suggest.prefecture_id)
        addressController.prefectureChanged().then(() => {
          $("[name='estate[city_id]']").val(estateData.estate_suggest.city_id)
          return addressController.cityChanged()
        })
        // 交通
        let stationSearchController_1 = document.querySelectorAll('[data-controller="estate-station-search"]')[0]['estate-station-search']
        $('#estate_estate_accesses_attributes_0_line_name-selectized').val(estateData.estate_suggest.line_name_1)
        $('#estate_estate_accesses_attributes_0_line_name')[0].options[0].value = estateData.estate_suggest.line_name_1
          stationSearchController_1.lineChanged(estateData.estate_suggest.line_name_1).then(() => {
            $(`[name='estate[estate_accesses_attributes][0][station_name]']`).val(estateData.estate_suggest.station_name_1)
          })
        $("[name='estate[estate_accesses_attributes][0][walking_minutes_from_station]']").val(estateData.estate_suggest.walking_minutes_from_station_1)

        let stationSearchController_2 = document.querySelectorAll('[data-controller="estate-station-search"]')[1]['estate-station-search']
        $('#estate_estate_accesses_attributes_1_line_name-selectized').val(estateData.estate_suggest.line_name_2)
        $('#estate_estate_accesses_attributes_1_line_name')[0].options[0].value = estateData.estate_suggest.line_name_2
          stationSearchController_2.lineChanged(estateData.estate_suggest.line_name_2).then(() => {
            $(`[name='estate[estate_accesses_attributes][1][station_name]']`).val(estateData.estate_suggest.station_name_2)
          })
        $("[name='estate[estate_accesses_attributes][1][walking_minutes_from_station]']").val(estateData.estate_suggest.walking_minutes_from_station_2)

        let stationSearchController_3 = document.querySelectorAll('[data-controller="estate-station-search"]')[2]['estate-station-search']
        $('#estate_estate_accesses_attributes_2_line_name-selectized').val(estateData.estate_suggest.line_name_3)
        $('#estate_estate_accesses_attributes_2_line_name')[0].options[0].value = estateData.estate_suggest.line_name_3
          stationSearchController_3.lineChanged(estateData.estate_suggest.line_name_3).then(() => {
            $(`[name='estate[estate_accesses_attributes][2][station_name]']`).val(estateData.estate_suggest.station_name_3)
          })
        $("[name='estate[estate_accesses_attributes][2][walking_minutes_from_station]']").val(estateData.estate_suggest.walking_minutes_from_station_3)
        // 築年月
        const built_month_arr = estateData.estate_suggest.built_month.split("-")
        $("[name='estate[built_month(1i)]']").val(built_month_arr[0])
        $("[name='estate[built_month(2i)]']").val(parseInt(built_month_arr[1]))
        // 総戸数
        $("[name='estate[total_units]']").val(estateData.estate_suggest.total_units)
        // 建物階数 地上
        $("[name='estate[above_ground_floor_count]']").val(estateData.estate_suggest.floor)

        // 構造(主要)
        $(`[name='estate[main_structure]']`).prop('checked', false)
        $(`[name='estate[main_structure]']`).siblings('.m-radio__item').children().removeClass('is-checked')
        if(estateData.estate_suggest.main_structure) {
          $(`[name='estate[main_structure]'][value=${estateData.estate_suggest.main_structure}]`).trigger('click')
        } else {
          $(`[name='estate[main_structure]'][value='']`).trigger('click')
        }

        // 土地権利
        $(`[name='estate[land_right]']`).prop('checked', false)
        $(`[name='estate[land_right]']`).siblings('.m-radio__item').children().removeClass('is-checked')
        if(estateData.estate_suggest.land_right) {
          $(`[name='estate[land_right]'][value=${estateData.estate_suggest.land_right}]`).trigger('click')
        }

        // 用途地域１
        $(`[name='estate[use_district_1]']`).prop('checked', false)
        $(`[name='estate[use_district_1]']`).siblings('m-radio__item').children().removeClass('is-checked')
        if(estateData.estate_suggest.use_district) {
          $(`[name='estate[use_district_1]'][value=${estateData.estate_suggest.use_district}]`).trigger('click')
        } else {
          $(`[name='estate[use_district_1]'][value='']`).trigger('click')
        }

        // 施工会社
        if(estateData.estate_suggest.construction_contractor) {
          $("[name='estate[construction_contractor]']").val(estateData.estate_suggest.construction_contractor)
        } else {
          $("[name='estate[construction_contractor]']").val('')
        }
        // 管理会社
        if(estateData.estate_suggest.management_company) {
          $("[name='estate[management_company]']").val(estateData.estate_suggest.management_company)
        } else {
          $("[name='estate[management_company]']").val('')
        }

        // ペット
        $('#estate_allow_pet_').trigger('click')
        $(`[name='estate[pet_condition]']`).val('')
        if(estateData.estate_suggest.allow_pet) {
          $('#estate_allow_pet_true').trigger('click')
          $(`[name='estate[pet_condition]']`).val(estateData.estate_suggest.allow_pet)
        } else {
          $(`[name='estate[pet_condition]'][value='']`).trigger('click')
        }

        // 管理方式
        $(`[name='estate[management_method]']`).prop('checked', false)
        $(`[name='estate[management_method]']`).siblings('.m-radio__item').children().removeClass('is-checked')
        if(estateData.estate_suggest.management_method) {
          $(`[name='estate[management_method]'][value=${estateData.estate_suggest.management_method}]`).trigger('click')
        }

        // 管理人
        $(`[name='estate[manager_type]']`).prop('checked', false)
        $(`[name='estate[manager_type]']`).siblings('.m-radio__item').children().removeClass('is-checked')
        if(estateData.estate_suggest.manager_type) {
          $(`[name='estate[manager_type]'][value=${estateData.estate_suggest.manager_type}]`).trigger('click')
        }

        // 駐車場
        // if(estateData.estate_suggest.parking_status) {
        //   $(`[name='estate[parking_status]'][value=${estateData.estate_suggest.parking_status}]`).click()
        // }
        $(`[name='estate[parking_cost]']`).val(estateData.estate_suggest.parking_cost)

        // 駐輪場
        // if(estateData.estate_suggest.bicycle_parking_status) {
        //   $(`[name='estate[bicycle_parking_status]'][value=${estateData.estate_suggest.bicycle_parking_status}]`).click()
        // }
        $(`[name='estate[bicycle_parking_cost]']`).val(estateData.estate_suggest.bicycle_parking_cost)

        // バイク置き場
        // if(estateData.estate_suggest.motorcycle_parking_status) {
        //   $(`[name='estate[motorcycle_parking_status]'][value=${estateData.estate_suggest.motorcycle_parking_status}]`).click()
        // }
        $(`[name='estate[motorcycle_parking_cost]']`).val(estateData.estate_suggest.motorcycle_parking_cost)

        $(`[name='estate[feature_ids][]']`).prop('checked', false)
        $(`[name='estate[feature_ids][]']`).parents('.m-checkbox__item-checkbox').removeClass('is-checked')
        estateData.estate_feature_suggests.forEach((elem) => {
          $(`[name='estate[feature_ids][]'][value='${elem.feature_id}']`).trigger('click')
        })


        if(estateData.estate_near_facility_suggests) {
          const estateNearFacilitySuggests =
            estateData.estate_near_facility_suggests.sort(function(a,b){
              if (a.distance < b.distance ) return -1
              if (a.distance > b.distance ) return 1
              return 0
            })

          estateNearFacilitySuggests.forEach((elem, idx) => {
            $(`[name='estate[estate_near_facilities_attributes][${idx}][name]']`).val(elem.name)
            $(`[name='estate[estate_near_facilities_attributes][${idx}][distance]']`).val(elem.distance)
          })
        }
      },
      error: (json, status) => {}
    });
  }

  renovationClicked(event) {
    const target = event.target;

    if (target.value === undefined)
      return;

    this.toggleRenovationInputs(target.value);
  }

  toggleRenovationInputs(value) {
    const disabled = !(value === 'renovation_status_reform' || value == 'renovation_status_renovation');
    this.renovationInputTargets.forEach(input => {
      if (input.disabled === disabled)
        return;

      input.disabled = disabled;

      switch (input.type) {
      case 'select-one':
        input.closest('.m-select').classList.toggle('is-disabled');
        break;
      case 'radio':
        input.closest('.m-radio__item-radio').classList.toggle('is-disabled');
        break;
      }
    });
  }

  repairFundTotalClicked(e) {
    this.toggleFundTotalInput(e.target.value)
  }

  toggleFundTotalInput(v) {
    if (v !== 'repair_fund_total_status_present'){
      this.repairFundTotalInputTarget.disabled = true
      $("#repair_fund_total")[0].innerHTML = '<input class="a-text-input is-sm" data-target="estate-form.repairFundTotalInput" type="text" value="" name="estate[repair_fund_total]" id="estate_repair_fund_total" disabled="">'
    } else {
      this.repairFundTotalInputTarget.disabled = false
    }
  }

  repairFundNonPaymentClicked(e) {
    this.toggleRepairFundNonPayment(e.target.value)
  }

  toggleRepairFundNonPayment(v) {
    if (v !== 'repair_fund_non_payment_status_present'){
      this.repairFundNonPaymentInputTarget.disabled = true
      $("#repair_fund_non_payment")[0].innerHTML = '<input class="a-text-input is-sm" data-target="estate-form.repairFundNonPaymentInput" type="text" value="" name="estate[repair_fund_non_payment]" id="estate_repair_fund_non_payment" disabled="">'
    } else {
      this.repairFundNonPaymentInputTarget.disabled = false
    }
  }

  managementFeeNonPaymentClicked(e) {
    this.togglemanagementFeeNonPayment(e.target.value)
  }

  togglemanagementFeeNonPayment(v) {
    if (v !== 'management_fee_non_payment_status_present'){
      this.managementFeeNonPaymentInputTarget.disabled = true
      $("#management_fee_non_payment")[0].innerHTML = '<input class="a-text-input is-sm" data-target="estate-form.managementFeeNonPaymentInput" type="text" value="" name="estate[management_fee_non_payment]" id="estate_management_fee_non_payment" disabled="">'
    } else {
      this.managementFeeNonPaymentInputTarget.disabled = false
    }
  }

  managementAssociationDebitClicked(e) {
    this.togglemanagementAssociationDebit(e.target.value)
  }

  togglemanagementAssociationDebit(v){
    if (v !== 'management_association_debit_status_present'){
      this.managementAssociationDebitInputTarget.disabled = true
      $("#management_association_debit")[0].innerHTML = '<input class="a-text-input is-sm" data-target="estate-form.managementAssociationDebitInput" type="text" value="" name="estate[management_association_debit]" id="estate_management_association_debit" disabled="">'
    } else {
      this.managementAssociationDebitInputTarget.disabled = false
    }
  }

  fileChanged(event) {
    const target = event.currentTarget;
    const file = target.files[0];
    const preview = target.closest('.m-form-image-field').querySelector('.m-file-input__preview');
    const deleteCheck = target.closest('.m-form-image-field').querySelector('input[type=checkbox]');
    deleteCheck.value = '0';
    this.handleFile(file, preview, 'changed');
  }

  handleFile(file, preview, eventType) {
    if (!this.isValid(file))
      return;

    const videoFile = file.type === 'video/mp4';
    const reader = new FileReader();

    if (videoFile) {
      this.disableSubmitButtons();
    }

    reader.onload = (e) => {
      let imgOrVideo;

      if (videoFile) {
        imgOrVideo = document.createElement('video');
        imgOrVideo.controls = true;
      } else {
        imgOrVideo = document.createElement('img');
      }

      imgOrVideo.classList.add('preview');
      imgOrVideo.src = e.target.result;

      if (eventType === 'dropped') {
        imgOrVideo.file = file;
      }

      preview.innerHTML = '';
      preview.appendChild(imgOrVideo);

      if (videoFile) {
        this.enableSubmitButtons();
      }
    };

    reader.readAsDataURL(file);
  }

  isValid(file) {
    if (!file)
      return false;

    if (file.type === 'video/mp4') {
      if (file.size > 3145728 * 10) {
        alert('ファイルサイズは最大30MBです');
        return false;
      }
    }
    else{
      if (this.fileTypes.indexOf(file.type.split('/')[1]) < 0) {
        alert('画像ファイル（jpg, png）のみアップロードすることができます。');
        return false;
      }

      if (file.size > 3145728) {
        alert('ファイルサイズは最大3MBです');
        return false;
      }
    }

    return true;
  }

  setFileControllers() {
    this.documentControllers = Array.from(document.querySelectorAll('[data-controller=\'estate-document\']')).map(document => {
      return this.application.getControllerForElementAndIdentifier(
        document, 'estate-document'
      );
    });

    this.photoControllers = Array.from(document.querySelectorAll('[data-controller=\'estate-photo\']')).map(photo => {
      return this.application.getControllerForElementAndIdentifier(
        photo, 'estate-photo'
      );
    });
  }

  enableFileInputs(target) {
    target.disabled = false;

    this.documentControllers.forEach(documentController => {
      documentController.enableFileInput();
    });

    this.photoControllers.forEach(photoController => {
      photoController.enableFileInput();
    });
  }

  disableFileInputs(target) {
    target.disabled = true;

    this.documentControllers.forEach(documentController => {
      documentController.disableFileInput();
    });

    this.photoControllers.forEach(photoController => {
      photoController.disableFileInput();
    });
  }

  isUploading() {
    const uploadingDocument = this.documentControllers.find(documentController => {
      return documentController.isUploading();
    });

    if (uploadingDocument)
      return true;

    const uploadingPhoto = this.photoControllers.find(photoController => {
      return photoController.isUploading();
    });

    if (uploadingPhoto)
      return true;

    return false;
  }

  disableSubmitButtons(target) {
    this.submitButtonTargets.forEach(submitButton => {
      if (submitButton === target)
        Rails.disableElement(submitButton);
      else
        submitButton.disabled = true;
    });
  }

  enableSubmitButtons(target) {
    this.submitButtonTargets.forEach(submitButton => {
      if (submitButton === target)
        Rails.enableElement(submitButton);
      else
        submitButton.disabled = false;
    });
  }

  destroyMainPhoto() {
    const target = this.mainPhotoTarget;
    const preview = target.closest('.m-form-image-field').querySelector('.m-file-input__preview');
    preview.innerHTML = '<i class="fas fa-image"></i><p>画像をここへ<br>ドラッグアンドドロップ</p>';
    this.mainPhotoCaptionTarget.value = '';
  }

  destroyRoomPlanPhoto() {
    const target = this.roomPlanPhotoTarget;
    const preview = target.closest('.m-form-image-field').querySelector('.m-file-input__preview');
    preview.innerHTML = '<i class="fas fa-image"></i><p>画像をここへ<br>ドラッグアンドドロップ</p>';
    this.roomPlanPhotoCaptionTarget.value = '';
  }

  destroyMainVideo() {
    const target = this.mainVideoTarget;
    const preview = target.closest('.m-form-image-field').querySelector('.m-file-input__preview');
    preview.innerHTML = '<i class="fas fa-image"></i><p>画像をここへ<br>ドラッグアンドドロップ</p>';
    this.mainVideoCaptionTarget.value = '';
  }

  destroyPickUpPhoto(e) {
    const imageField = e.currentTarget.closest('.m-form-image-field')
    const preview = imageField.querySelector('.m-file-input__preview')
    preview.innerHTML = '<i class="fas fa-image"></i><p>画像をここへ<br>ドラッグアンドドロップ</p>'
    imageField.querySelector('.a-text-input').value = ''
    imageField.querySelector('.url').value = ''
  }

  submit(event) {
    event.stopPropagation();
    event.preventDefault();
    this.setFileControllers();

    if (this.isUploading()) {
      alert('ファイルをアップロード中のため、少しお待ち下さい');
      return;
    }

    const target = event.currentTarget;
    const form = document.querySelector('form.admin-main-form__form');
    const ajaxData = new FormData(form);
    this.disableSubmitButtons(target);
    this.disableFileInputs(target);

    if (target.name === 'publish_save_button')
      ajaxData.append('publish_save_button', true);

    this.renovationInputTargets.forEach(input => {
      if (!input.disabled)
        return;

      if (input.name === 'estate[renovate_month_period]' && !input.checked)
        return;

      ajaxData.append(input.name, input.value);
    });

    this.filePreviewTargets.forEach(preview => {
      const img = preview.firstChild;
      if (img && img.file) {
        const inputName = preview.parentNode.querySelector('input[type="file"]').name;
        ajaxData.append(inputName, img.file);
      }
    });

    Rails.ajax({
      type: 'POST',
      dataType: 'json',
      data: ajaxData,
      url: form.action,
      success: (json) => { location.href = json.location; },
      error: (json, status) => {
        this.enableSubmitButtons(target);
        this.enableFileInputs(target);

        if (json.error === '資料の種類の選択または資料名が入力されていない添付資料があります。') {
          alert('資料の種類の選択または資料名が入力されていない添付資料があります。');

          return;
        }

        if (json.error === '添付資料が提供の場合はPDFファイルを登録してください。') {
          alert('添付資料が提供の場合はPDFファイルを登録してください。');

          return;
        }
        if ((status === 'Unprocessable Entity' || status === '') && json.errors)
          this.renderErrorMessages(json);
        else
          this.showAlert();
      }
    });
  }

  renderErrorMessages(json) {
    const errors = json.errors
    const ul = this.errorContentTarget.querySelector('ul');
    ul.innerHTML = '';

    errors.forEach(error => {
      const li = document.createElement('li');
      li.classList.add('estates-show-contact-errors__item');
      li.textContent = error;
      ul.appendChild(li);
    });

    this.errorContentTarget.style.display = 'block';
    window.scrollTo(0, 0);
    this.renderEachErrorMessages(json.errorsDetail);
  }

  renderEachErrorMessages(errors) {
    const elements = document.getElementsByClassName('errors-messages');
    for (const element of elements) {
      element.innerHTML = '';
    }

    Object.keys(errors).forEach(function (key) {
      const p = document.createElement('p');
      const div = document.querySelector("div.errors-messages." + key);
      if (div) {
        const str = document.createTextNode(errors[key]);
        p.classList.add('estates-show-contact-errors__item');
        p.classList.add('text-red');
        p.appendChild(str);
        div.appendChild(p);
      }
    });
  }

  showAlert() {
    alert('エラーが発生しました。ブラウザをリロードして、再度お試しください。');
  }

  get fileTypes() {
    return ['jpg', 'jpeg', 'png'];
  }
}
/* eslint-disable */
