import Rails from 'rails-ujs';
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [ 'checkBox' ]

  onCheck() {
    if (this.checkBoxTarget.checked){
      if (confirm('売却機能を追加してよろしいですか？')){
        this.updateEstateSellRequestAllowed()
      } else {
        this.checkBoxTarget.checked = false
      }
    } else {
      if (confirm('売却機能を解除してよろしいですか？')){
        this.updateEstateSellRequestAllowed()
      } else {
        this.checkBoxTarget.checked = true
      }
    }
  }

  updateEstateSellRequestAllowed(){
    Rails.ajax({
      type: 'PATCH',
      dataType: 'json',
      url: `/api/users/${ this.checkBoxTarget.defaultValue }/update_estate_sell_request_allowed?estate_sell_request_allowed=${ this.checkBoxTarget.checked }`,
      success: (json) => {
        if (json.estate_sell_request_allowed) {
          alert('売却機能を追加しました')
        } else {
          alert('売却機能を解除しました')
        }
      },
      error: (json) => {
        alert('エラーが発生しました。ブラウザをリロードして、再度お試しください。')
      }
    })
  }
}
