import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    this.set_events();
  }

  /*
   * イベント設定
   */
  set_events() {
    // チャットリンクをテキストエリアに送る
    $('#chat_area_items').on('click', '.message-item__link', function() {
      const link_datas = $(this).data();
      let temp_text = $('#chat_form_textarea').val();
      $('#chat_form_textarea').val(temp_text + '\r\n' + link_datas.clUrl + '\r\n');
      if ($.trim($('#chat_form_textarea').val()).length === 0) {
        $('#chat_form_submit').addClass('stop_submit');
      } else {
        $('#chat_form_submit').removeClass('stop_submit');
      }
    });

    // チャットコンテツ内スクロール
    // チャット内の最上部にスクロールしたら前ページ取得
    // チャット内の最下部にスクロールしたら次ページ取得
    $('#chat_area_display').on('scroll', function() {
      const page_datas = $('#chat_area_display').data();
      if (page_datas.isPageLoading) {
        return false;
      }
      const $chat_area_display = $('#chat_area_display');
      const $area_items = $('#chat_area_items');
      const display_height = $chat_area_display.height(); // 表示領域の下部
      const display_scroll_top = $chat_area_display.scrollTop(); //　表示領域のスクロール量
      const items_height = $area_items.height(); // リストの下部
      const check_height = display_height + display_scroll_top - items_height;

      if (display_scroll_top <= 0) {
        if (!page_datas.existFirstPostedContent) {
          // 前ページ
          console.log('get before page');
          const target_time = page_datas.chatDisplayingBeginPostedAt;
          const target_time_utc = page_datas.chatDisplayingBeginPostedAtUtc;
          const url = '/admin/message_realtors/' + page_datas.realtorId + '/messages/' + page_datas.chatId + '/chat_page_back?target_time=' + target_time_utc
          $('#chat_area_display').data('isPageLoading', true);
          $.ajax({
            type: 'GET',
            url: encodeURI(url),
            success: () => {
              // TOPまでスクロールしてしまうので取得位置まで戻す
              const chat_area_items = document.getElementsByClassName('chat_area_item');
              const chat_area_items_array = Array.prototype.slice.call(chat_area_items);
              chat_area_items_array.forEach(function(value, index, array) {
                if (index > 0) {
                  const posted_at = value.dataset.postedAt;
                  const just_before_posted_at = array[index - 1].dataset.postedAt;
                  // chatDisplayingBeginPostedAtは書き換わるのでtarget_timeの位置にスクロール
                  if (posted_at > target_time && just_before_posted_at <= target_time) {
                    const rect = value.getBoundingClientRect(); // 移動させたい位置の要素を取得
                    const position = rect.top;    // 一番上からの位置を取得
                    const area = document.getElementById('chat_area_display');
                    area.scrollTo(0, position - 280);
                  }
                }
              });
            },
            error: () => {},
            complete: () => {
              $('#chat_area_display').data('isPageLoading', false);
            }
          });
        }
      } else if (Math.ceil(check_height) >= 0) {
        if (!page_datas.existLatestPostedContent) {
          // 次ページ
          console.log('get forward page');
          const url = '/admin/message_realtors/' + page_datas.realtorId + '/messages/' + page_datas.chatId + '/chat_page_forward?target_time=' + page_datas.chatDisplayingEndPostedAtUtc
          $('#chat_area_display').data('isPageLoading', true);
          $.ajax({
            type: 'GET',
            url: encodeURI(url),
            success: () => {},
            error: () => {},
            complete: () => {
              $('#chat_area_display').data('isPageLoading', false);
            }
          });
        }
      }
    });
  }
}
