import { Controller } from 'stimulus';

// TODO: リファクタ：dataLayer変数そのものをviewで使いたい。
// this.data.get['???']のような値の取得の仕方だと、viewと密結合になりそう。（viewの詳細を知らないといけなさそう。）
export default class extends Controller {
  connect() {
    (async () => {
      window.dataLayer = window.dataLayer || [];

      const initialData = await this.getInitialData();
      // eslint-disable-next-line no-undef
      dataLayer.push(initialData);

      this.setupGtmTag();
    })();
  }

  // TODO: リファクタ。if文が多いので、ポリモーフィズムを使った方が良いかな。
  async getInitialData() {
    const data = {};

    if (this.isLoggedIn) {
      try {
        const user = await this.fetchUser();
        Object.assign(data, this.getUserForDataLayer(user));
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    }

    if (this.isEstatePage) {
      Object.assign(data, this.getDataForEstatePage());
    }

    if (this.isThanksPage) {
      // getUserForDataLayerでassignしたname, email, phoneを上書きしてしまう可能性があるので対応。
      // TODO: 呼び出し側が、呼び出し先の内部実装を知りすぎてしまってるので、リファクタしたい。
      const thanksPageData = this.getDataForThanksPage();
      if (this.isLoggedIn) {
        thanksPageData['name'] = data['name'];
        thanksPageData['email'] = data['email'];
        thanksPageData['sha256_email'] = data['sha256_email'];
        thanksPageData['phone'] = data['phone'];
      }
      Object.assign(data, thanksPageData);
    }

    if (this.isSearchEstatesPage) {
      Object.assign(data, this.getDataForSearchEstatesPage());
    }

    return data;
  }

  async fetchUser() {
    const res = await fetch(`/api/users/${this.currentUserId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!res.ok) {
      throw new Error(res.statusText);
    }
    return res.json();
  }

  getUserForDataLayer(user) {
    const name = this.inquiryName || user.name;
    const email = this.inquiryEmail || user.email;
    const sha256Email = this.inquirySha256Email || user.sha256_email;
    const phone = this.inquiryTel || user.tel;

    return {
      user_id: user.id,
      created_at: user.created_at,
      phone: phone,
      email: email,
      sha256_email: sha256Email,
      name: name,
      registration_medium: user.registration_medium,
      age: user.age,
      gender: user.gender,
      family: user.family,
      annual_income: user.annual_income,
      cond_cities: user.condition_cities,
      cond_min_price: user.condition_min_price,
      cond_max_price: user.condition_max_price,
      cond_min_footprint: user.condition_min_footprint,
      cond_max_footprint: user.condition_max_footprint,
      cond_allow_pet: user.condition_allow_pet,
      cond_allow_office: user.condition_allow_office,
      current_state_rent: user.condition_current_state_rent,
      walking_minutes_from_station: user.condition_walking_minutes_from_station,
      second_floor_above: user.condition_second_floor_above,
      use_favorite: user.favorites,
      use_conditions: user.conditions,
      use_requie: user.hope_estates,
      user_hash: user.user_hash,
      estate_id: this.estateId,
      estate_realtor: this.inquiryEstateRealtor,
      estate_name: this.inquiryEstateName,
      estate_address: this.inquiryEstateAddress,
      estate_price: Number(this.inquiryEstatePrice),
      onlook_request_date: this.onlookRequestDate,
      inquiry_body: this.inquiryBody,
      line_user_id: user.line_user_id,
    };
  }

  getDataForEstatePage() {
    return {
      estate_id: this.estateId,
      estate_realtor: this.inquiryEstateRealtor,
      estate_name: this.inquiryEstateName,
      estate_address: this.inquiryEstateAddress,
      estate_price: Number(this.inquiryEstatePrice),
      smaview_available: this.smaviewAvailable === 'true',
      ecocube: this.ecocube === 'true',
      tax_deduction_available: this.taxDeductionAvailable === 'true',
    };
  }

  getDataForThanksPage() {
    return {
      phone: this.inquiryTel,
      email: this.inquiryEmail,
      sha256_email: this.inquirySha256Email,
      name: this.inquiryName,
      estate_id: this.estateId,
      estate_realtor: this.inquiryEstateRealtor,
      estate_name: this.inquiryEstateName,
      estate_address: this.inquiryEstateAddress,
      estate_price: Number(this.inquiryEstatePrice),
      id: this.id,
      onlook_request_date: this.onlookRequestDate,
      body: this.inquiryBody,
      allow_weekly_email: this.allowWeeklyEmail,
      allow_sales_email: this.allowSalesEmail,
    };
  }

  getDataForSearchEstatesPage() {
    return {
      estate_ids: JSON.parse(this.estateIds),
      estate_total: Number(this.estateTotal),
    };
  }

  setupGtmTag() {
    if (this.isProduction === 'true') {
      (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', 'GTM-58NSMFR');
    } else if (this.isStaging === 'true') {
      (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', 'GTM-P28WC6D');
    }
  }

  get isLoggedIn() {
    return this.currentUserId != null;
  }

  get currentUserId() {
    return this.data.get('currentUserId');
  }

  get isProduction() {
    return this.data.get('isProduction');
  }

  get isStaging() {
    return this.data.get('isStaging');
  }

  get estateId() {
    return this.data.get('estateId');
  }

  get smaviewAvailable() {
    return this.data.get('smaviewAvailable');
  }

  get ecocube() {
    return this.data.get('ecocube');
  }

  get taxDeductionAvailable() {
    return this.data.get('taxDeductionAvailable');
  }

  get id() {
    return this.data.get('id');
  }

  get inquiryName() {
    return this.data.get('inquiryName');
  }

  get inquiryEmail() {
    return this.data.get('inquiryEmail');
  }

  get inquirySha256Email() {
    return this.data.get('inquirySha256Email');
  }

  get inquiryTel() {
    return this.data.get('inquiryTel');
  }

  get inquiryEstateRealtor() {
    return this.data.get('inquiryEstateRealtor');
  }

  get inquiryEstateName() {
    return this.data.get('inquiryEstateName');
  }

  get inquiryEstateAddress() {
    return this.data.get('inquiryEstateAddress');
  }

  get inquiryEstatePrice() {
    return this.data.get('inquiryEstatePrice');
  }

  get onlookRequestDate() {
    return this.data.get('onlookRequestDate');
  }

  get inquiryBody() {
    return this.data.get('inquiryBody');
  }

  get isEstatePage() {
    return this.data.get('isEstatePage');
  }

  get isThanksPage() {
    return this.data.get('isThanksPage');
  }

  get allowWeeklyEmail() {
    return this.data.get('allowWeeklyEmail');
  }

  get allowSalesEmail() {
    return this.data.get('allowSalesEmail');
  }

  get isSearchEstatesPage() {
    return this.data.get('isSearchEstatesPage') === 'true';
  }

  get estateIds() {
    return this.data.get('estateIds');
  }
  get estateTotal() {
    return this.data.get('estateTotal');
  }
}
