import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = []

  connect() {
    addEventListener('DOMContentLoaded', () => {
        document.querySelectorAll("input,textarea").forEach(function(element) {
            element.addEventListener("invalid", function(e) {
                if(element.validity.valueMissing){
                    e.target.setCustomValidity("この項目は入力が必須です。");
                } else {
                    e.target.setCustomValidity("");
                }
            });
        });
    });
  }
}
