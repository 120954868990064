import Rails from 'rails-ujs';
import { Controller } from 'stimulus';

export default class extends Controller {

  static targets = ['filePreview', 'photo'];

  fileChanged(event) {
    const target = event.currentTarget;
    const file = target.files[0];
    if (this.isValid(file)) {
      const preview = this.filePreviewTarget;
      this.handleFile(file, preview);
    }
  }

  handleFile(file, preview) {
    const reader = new FileReader();
    reader.onload = (e) => {
      const img = document.createElement('img');
      img.classList.add('preview');
      img.src = e.target.result;
      preview.innerHTML = '';
      preview.appendChild(img);
    };
    reader.readAsDataURL(file);
  }

  isValid(file) {
    const allowedTypes = ['image/jpeg', 'image/png'];
    if (!file) {
      return false;
    }
    if (!allowedTypes.includes(file.type)) {
      alert('画像ファイル（jpg, png）のみアップロードすることができます。');
      return false;
    }
    if (file.size > 10 * 1024 * 1024) {
      alert('ファイルサイズは最大10MBです');
      return false;
    }
    return true;
  }

  destroyPhoto() {
    const preview = this.filePreviewTarget;
    preview.innerHTML = '<i class="fas fa-image"></i>';
    this.photoTarget.value = '';
  }
}
