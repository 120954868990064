import Rails from 'rails-ujs';
import { Controller } from 'stimulus';
import 'selectize'
import $ from 'jquery'

export default class extends Controller {
  static targets = [ 'prefecture', 'line', 'station', 'submitButton', 'forSearch', 'lineForm' ]

  connect() {
    $(this.lineFormTarget).selectize({
      onChange: (value) => {
        this.lineChanged(value);
      }
    });
  }

  lineChanged(value) {
    let accessPromise = this.getAccess(value)
    this.stationTarget.innerHTML = '';

    return accessPromise;
  }

  getAccess(params) {
    const self = this;
    let url = `/api/accesses?line_name=${params}`
    return new Promise(function(resolve, reject) {
      Rails.ajax({
        type: 'GET',
        dataType: 'json',
        url: url,
        success: (json) => {
          self.renderStationSelectOptions(json.stations);
            resolve(json);
        },
        error: () => {
          self.showAlert();
          reject();
        }
      });
    });
  }

  renderStationSelectOptions(results) {
    this.renderSelectOptions(this.stationTarget, results, "駅");
  }

  renderSelectOptions(target, results, default_text) {
    if (!results)
      return;

    const option = document.createElement('option');
    option.text = default_text;
    option.value = "";
    target.add(option);
    results.forEach(place => {
      const option = document.createElement('option');
      option.value = place.name;
      option.text = place.name;
      target.add(option);
    });
  }
}
