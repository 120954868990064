(function($) {
	$(function() {
		//detect IE11 browser
		const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
		if(isIE11) {
			$('body').addClass('ie11');
		}

    $("#sidebar").stick_in_parent();

		let header = $('#header');
		let checkbox = $('.recently__item').find('input[type="checkbox"]');

		$(".page-top a").click(function() {
			$("html, body").animate({ scrollTop : 0 });
			return false;
		});

		$('.anchor-link').click(function() {
			if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
				var target = $(this.hash);
				target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
				if (target.length) {
					$('html,body').animate({
						scrollTop: target.offset().top
					}, 1000);
					return false;
				}
			}
		});

		$('.tab a').on('click', function(e) {
			e.preventDefault();
			let target = $(this).attr('href');

			$(this).parent().addClass('active').siblings().removeClass('active');
			$(target).fadeIn(0).siblings().fadeOut(0);
		});

		if ($('#area_search_page').length === 1) {
		  $('.tab:not(.tab--default)').find(`[id=${ prefecture_id } ]`).trigger('click');
		} else {
		  $('.tab.tab--default li:first-child a').trigger('click');
		}

		$('.accordion-dl dt').on('click', function() {
			let dt = $(this);
			dt.toggleClass('active').siblings('dt').removeClass('active');
			dt.next('dd').stop().slideToggle(200).siblings('dd').stop().slideUp(200);
		});
		$('.accordion-dl dt:first-child').trigger('click');

    // TODO 同じ処理か確認
    // https://github.com/mansion-market/flie/blob/stg/app/views/estates/show.html.slim#L218-L230
    // https://github.com/mansion-market/flie/blob/stg/app/views/estates/show.html.slim#L252-L264
		$('.show-popup').on('click', function(e) {
			e.preventDefault();
			let dataID = $(this).data('id');

			$('.popup[data-id="'+dataID+'"]').fadeIn().scrollTop(0);
		});

		$('body').on('click', '.popup-close, .popup .overlay, .popup [data-close]', function(e){
			e.preventDefault();
			$(this).parents('.popup').stop().fadeOut();
			$('body').removeClass('noscroll');
		});

		var scrollTop = 0;
		var scrollLeft = 0;
		function stopScroll() {
			scrollTop = $(window).scrollTop();
			scrollLeft = $(window).scrollLeft();

			$('body').addClass('noscroll').css('top', (-scrollTop) + 'px');
		}

		$('.search-head input[type="checkbox"]').on('change', function() {
			if( $(this).is(':checked') ) {
				$(this).closest('.search-head').next('.search-body').find('input').prop('checked', true);
			} else {
				$(this).closest('.search-head').next('.search-body').find('input').prop('checked', false);
			}
		});

		$('.hamburger-box').on('click', function() {
			$(this).toggleClass('is-active');
			header.find('.navigation').stop().slideToggle(300);
		});

		const recentlySlider = $('.recently__list:not(.noslider)');

		$(window).on('load resize', function() {
			if( $(window).width() < 1201 ) {
				if( !recentlySlider.hasClass('slick-initialized') ) {
					recentlySlider.slick({
						slidesToShow: 3,
						arrows: false,
						dots: true,
						swipeToSlide: true,
						responsive: [
							{
								breakpoint: 768,
								settings: {
									slidesToShow: 1,
									variableWidth: true,
									centerPadding: '0',
									arrows: false
								}
							}
						]
					});
				}
			} else {
				if( recentlySlider.hasClass('slick-initialized') ) {
					recentlySlider.slick('unslick');
				}
			}
		});

		checkbox.on('change', function() {
			let getInputLength = $('.recently__item').find('input[type="checkbox"]:checked').length;

			if( getInputLength > 0 ) {
				$('.btn-delete-select').prop('disabled', false);
			} else {
				$('.btn-delete-select').prop('disabled', true);
			}

			if( $(this).is(':checked') ) {
				$(this).closest('.recently__list--slider').find(`input[value=${this.value}]`).each(function() {
					$(this).prop('checked', true);
					$(this).closest('.recently__item').addClass('checked');
				})
			} else {
				$(this).closest('.recently__list--slider').find(`input[value=${this.value}]`).each(function() {
					$(this).prop('checked', false);
					$(this).closest('.recently__item').removeClass('checked');
				})
			}
		});

		let showGrid = $('#show-grid');
		let showList = $('#show-list');
		let prodShow = $('.prod-show');

		if ($(window).width() < 767) {
			prodShow.addClass('pro-list').removeClass('pro-grid');
		}

		showList.on('click', function() {
			$(this).addClass('active');
			showGrid.removeClass('active');
			prodShow.addClass('pro-list').removeClass('pro-grid');
		});

		showGrid.on('click', function() {
			$(this).addClass('active');
			showList.removeClass('active');
			prodShow.addClass('pro-grid').removeClass('pro-list');
		});

		$('.grid-hamburger').on('click', function() {
			$('#sidebar').stop().slideToggle(300);
		});

		$('.show-more-txt').on('click', function(e) {
			e.preventDefault()
			if ($(this).hasClass('clicked')) {
				$(this).removeClass('clicked')
        $(this).parent().removeClass('show-more')
			} else {
        $(this).addClass('clicked')
			  $(this).parent().addClass('show-more')
      }
		})

		let dropdown = $('.dropdown');

		dropdown.find('.btn-toggle').on('click', function() {
			let dropdownBox = $(this).next();

			dropdownBox.removeAttr('style');
			if( $(this).hasClass('active') ) {
				$(this).removeClass('active');
				dropdownBox.fadeOut(200);
			} else {
				$(this).addClass('active');
				dropdownBox.fadeIn(200);
			}

			$(this).parent().siblings('.dropdown').each(function(){
				let button = $(this).find('.btn-toggle');
				let dropdownBox = button.next();

				button.removeClass('active');
				dropdownBox.fadeOut(200);
			});

			dropdownBox.css('max-height', $(window).height() - dropdownBox.offset().top - 15);
			dropdownBox.css('right', 0);
		});

		$('.close-dropdown-box').on('click', function() {
			let dropdownBox = $(this).parent();
			dropdownBox.removeAttr('style');
			dropdownBox.parent().children().removeClass('active');
		})

		$('[name="close-erea-dropdown-box"]').on('click', function() {
			let dropdownBox = $(this).parents('.dropdown-box')
			dropdownBox.removeAttr('style');
			dropdownBox.parent().children().removeClass('active');
		})

		$(document).mouseup(function(e) {
				var container = $('.dropdown-box');
				var button = $('.dropdown .btn-toggle');

			if (!button.is(e.target) && !container.is(e.target) && container.has(e.target).length === 0)
			{
				container.prev().removeClass('active');
				container.fadeOut(200);
			}
		});

		$('button[data-target]').on('click', function() {
			let target = $(this).data('target');
			console.log(target)

			$(target).stop().slideDown(200);
		})
	});
})(jQuery);
