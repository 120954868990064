import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'estateNameInput' ]

  onClick(e) {
    if (!confirm(`${this.estateNameInputTarget.value}の売却を依頼します。お間違いありませんか？`)){
      e.preventDefault()
    }
  }
}
