import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.modalElement = document.getElementById('client-accept-call-log-customer-transfer');
    this.memoElement = this.modalElement.querySelector('.customer-transfer-memo');

    // モーダルが閉じた後のイベントリスナーを設定
    this.modalElement.addEventListener('hidden.bs.modal', () => this.resetModal());
  }

  async acceptCustomerTransfer(event, customerTransferId) {
    event.preventDefault();

    try {
      const response = await fetch(`/api/client_customer_transfers/${customerTransferId}/accept`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
        },
        body: JSON.stringify({ }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      alert('送客を受け入れました');
      location.href = `/client/customer_transfers/${customerTransferId}`;
    } catch (error) {
      alert('送客の受け入れに失敗しました');
      location.reload();
    }
  }

  confirmAccept(event) {
    event.preventDefault();

    this.modalElement.dataset.customerTransferId = event.target.dataset.customerTransferId;
    this.memoElement.textContent = event.target.dataset.customerTransferMemo;
    const modal = new bootstrap.Modal(this.modalElement);
    modal.show();
  }

  confirmSubmit(event) {
    event.preventDefault();
    const customerTransferId = this.modalElement.dataset.customerTransferId;

    const result = confirm('送客を受け入れます。送客を受け入れると課金が行われます。');
    if (result) {
      this.acceptCustomerTransfer(event, customerTransferId);
    }
  }


  // モーダルが閉じたときに実行されるリセット関数
  resetModal() {
    // 表示文言を初期値に戻す
    this.memoElement.textContent = '読み込み中...';
  }
}
