import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    this.set_events();
  }

  /*
   * イベント設定
   */
  set_events() {
    // ファイルアップ（ブラウザ内）時のバリデーション
    function validateFiles(files) {
      let acceptableContentType = [
        'image/jpeg',
        'image/png',
        'application/pdf',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ];

      if (files.length) {
        if (files.length > 1) {
          alert('1ファイルずつアップして下さい');
          return false
        } else if (!acceptableContentType.includes(files[0].type)) {
          alert('このファイルは選択できません');
          return false
        } else {
          return true
        }
      } else {
        return false
      }
    }

    // ファイルアップ（ブラウザ内）時にファイル名を表示
    function showFiles(files) {
      if (validateFiles(files)) {
        let output = $('.upload-result');

        output.append('<p class="file-item">' + files[0].name + '<span class="close"></span></p>');
        $('.btn-attatch').addClass('disabled');
        $('.btn-attatch input').prop('disabled', true);
        $('#chat_form_submit').removeClass('stop_submit');
      } else {
        $('.btn-attatch input').val('')
        if ($.trim($('#chat_form_textarea').val()).length === 0) {
          $('#chat_form_submit').addClass('stop_submit');
        }
      }
    }

    // ファイルアップ（ブラウザ内）
    $('.btn-attatch input').off().on('change', function(e) {
      showFiles(e.target.files);
    });

    // ファイルの削除
    $('body').on('click', '.file-item .close', function() {
      $('.btn-attatch').removeClass('disabled');
      $('.btn-attatch input').prop('disabled', false);
      $('.btn-attatch input').val('')
      if ($.trim($('#chat_form_textarea').val()).length === 0) {
        $('#chat_form_submit').addClass('stop_submit');
      }
      $(this).parent().fadeOut(300, function() {
        $(this).remove();
      });
    });

    // メッセージorファイルが空白の状態は送信させない
    $('#chat_form_textarea').on('keydown keyup keypress change', function() {
      const message = $(this).val();
      const message_lines = message.match(/\r\n|\n/g);
      const max_line = 8;
      const min_line = 3;
      const current_line = $(this).data('messageLine');
      if ($.trim(message).length === 0 && !$('.btn-attatch').hasClass('disabled')) {
        $('#chat_form_submit').addClass('stop_submit');
      } else {
        $('#chat_form_submit').removeClass('stop_submit');
      }


      if (message_lines !== null && message_lines.length !== current_line) {
        const body_height = $('.message-main__body').height();
        const message_height = $(this).height();
        const controll_height = 20;
        if (current_line < max_line && current_line < message_lines.length && message_lines.length >= min_line) {
          let add_lines = 0;
          if (current_line < min_line) {
            if (message_lines.length >= max_line) {
              // 行位置変更前に最大行数を超える行数が入力された場合は最大数で止める(メモ帳などからコピー)
              add_lines = max_line - min_line;
            } else {
              // 行位置変更前に最大行数を超えない入力の場合は入力行数から最小行数を引く(フォームない入力orメモ帳などから2,3行程度の少ないコピー)
              add_lines = message_lines.length - min_line;
            }
          } else {
            if (message_lines.length >= max_line) {
              // 行位置変更後に最大行数を超える行数が入力された場合は最大数と現在の行位置の差分で止める(メモ帳などからコピー)
              add_lines = max_line - current_line;
            } else {
              // 行位置変更後に最大行数を超えない入力の場合は入力行数から最小行数を引く(フォームない入力orメモ帳などから2,3行程度の少ないコピー)
              add_lines = message_lines.length - current_line;
            }
          }
          const add_height = controll_height * add_lines;
          $('.message-main__body').height(body_height - add_height);
          $(this).height(message_height + add_height);
          $(this).data('messageLine', message_lines.length);
        } else if (current_line > min_line && current_line > message_lines.length && message_lines.length < max_line) {
          let delete_line = 0;
          if (message_lines.length <= min_line) {
            if (current_line > max_line) {
              // 行位置変更前に最小行数を超える行数が削除された場合は最小数で止める(複数行選択して削除)
              delete_line = max_line - min_line;
            } else {
              // 行位置変更前に最小行数を超えない行数が削除された場合は最小数で止める(複数行選択して削除)
              delete_line = current_line - min_line;
            }
          } else {
            if (current_line >= max_line) {
              // 行位置変更後に最大行数まで削除された場合は最大数と現在の行位置の差分で止める(deleteキーなどから削除or複数行選択して削除)
              delete_line = max_line - message_lines.length;
            } else {
              // 行位置変更後に最大行数内で削除された場合は入力済行数と現在の行位置の差分で止める(deleteキーなどから削除or複数行選択して削除)
              delete_line = current_line - message_lines.length;
            }
          }
          const delete_height = controll_height * delete_line;
          $('.message-main__body').height(body_height + delete_height);
          $(this).height(message_height - delete_height);
          $(this).data('messageLine', message_lines.length);
        }
      }
    });

    // サブミット中の多重送信を止める
    $('#chat_form_submit').on('click', function() {
      // fileのdisabledを解除しないとファイルが送信されない
      $('.btn-attatch input').prop('disabled', false);
      $(this).addClass('stop_submit');
    });
  }
}
