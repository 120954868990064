import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'registerUserButton' ]

  connect() {
    this.store = {
      userId: '',
      client: '',
      accessToken: '',
      expiry: 0
    }

    const LIFF_ID = this.liffId

    liffInit()

    async function liffInit() {
      await liff.init({ liffId: LIFF_ID })

      // LINE アプリ外で開いた時はトップページに遷移させる
      if (!liff.isLoggedIn()) {
        location.href = '/'
      }
    }
  }

  async onClickRegisterUser() {
    this.registerUserButtonTarget.disabled = true
    const { userId } = liff.getContext()
    const accessToken = liff.getAccessToken()
    const idToken = liff.getIDToken()

    const res = await fetch('/liff/auth', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        uid: userId,
        access_token: accessToken,
        id_token: idToken
      })
    })

    if (res.status !== 200) {
      const data = await res.json();
      alert(data.message)

      liff.closeWindow()

      return
    }

    this.store.userId = userId
    this.store.client = res.headers.get('client')
    this.store.accessToken = res.headers.get('access-token')
    this.store.expiry = res.headers.get('expiry')

    await this.makeSignedInState()

    location.href = '/liff/user/thanks'
  }

  async makeSignedInState() {
    // 保存したアクセストークンを使用し、APIを呼んでログイン状態にする
    const res = await fetch('/liff/user/estate_conditions/make_signed_in_state', {
      headers: {
        'Content-Type': 'application/json',
        ...this.getAuthHeaders()
      }
    })

    return res
  }

  getAuthHeaders() {
    return {
      uid: this.store.userId,
      client: this.store.client,
      expiry: this.store.expiry,
      'access-token': this.store.accessToken
    }
  }

  get liffId() {
    return this.data.get('liffId')
  }
}
