import Rails from 'rails-ujs';
import { Controller } from 'stimulus';

export default class extends Controller {
  initialize() {
    // this.set_global_read_mark();
  }

  // 通知マーク(赤ポチ)をつける
  // TODO ページ遷移時に毎回呼ばれてしまうのでなんとかする
  set_global_read_mark() {
    Rails.ajax({
      type: 'GET',
      dataType: 'json',
      url: '/api/chats/admin_all_unread_ids',
      success: (data) => {
        const unread_array = data;
        const unread_mark = 'has-new';
        const global_message_link = document.getElementById('global_message_link');
        if (unread_array.length) {
          if (!global_message_link.classList.contains(unread_mark)) {
            global_message_link.classList.add(unread_mark);
          }
        } else {
          if (global_message_link.classList.contains(unread_mark)) {
            global_message_link.classList.remove(unread_mark);
          }
        }
      },
      error: () => {},
      complete: () => {}
    });
  }
}
