/* global google */

import GoogleMap from '../classes/google_map';
import { Controller } from 'stimulus';
import markerImg from '../../assets/images/pages/estates/marker.svg';

export default class extends Controller {
  static targets = [ 'content' ]

  initialize() {
    GoogleMap.dispatchCallbackEvent();
  }

  connect() {
    if (this.isModal)
      this.initializeMap();
  }

  initializeMap() {
    const mapOptions = this.mapOptions();
    const map = new google.maps.Map(this.contentTarget, mapOptions);
    new google.maps.Marker({
      map: map,
      position: mapOptions.center,
      icon: markerImg
    });
  }

  mapOptions() {
    const options = GoogleMap.options();
    options.zoom = 16;
    options.center = {
      lat: Number(this.data.get('lat')),
      lng: Number(this.data.get('lng'))
    };
    return options;
  }

  get isModal() {
    return typeof google !== 'undefined' && this.data.get('modal') === 'true';
  }
}
