import Rails from 'rails-ujs';
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [ 'prefecture', 'city' ]

  prefectureChanged() {
    if (!this.prefectureTarget.value) {
      this.cityTarget.innerHTML = null;
      return;
    }

    return this.getAddress({ prefecture_id: this.prefectureTarget.value });
  }

  getAddress(params) {
    let url = '/api/addresses?';
    Object.keys(params).forEach(key => url += `${key}=${params[key]}&`);
    let self = this;
    return new Promise(function(resolve, reject) {
      Rails.ajax({
        type: 'GET',
        dataType: 'json',
        url: url,
        success: (json) => { self.renderAddressSelectOptions(json); resolve(json); },
        error: () => { self.showAlert(); reject(); }
      });
    });
  }

  renderAddressSelectOptions(json) {
    let target, results;

    if (json.cities) {
      target = this.cityTarget;
      results = json.cities;
    }

    this.renderSelectOptions(target, results);
  }

  renderSelectOptions(target, results) {
    if (!target || !results)
      return;

    target.innerHTML = '';
    results.forEach(place => {
      const option = document.createElement('option');
      option.value = place.id;
      option.text = place.name;
      target.add(option);
    });
  }
}
