import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [ 'name', 'nameError', 'email', 'emailError', 'phoneNumber', 'phoneNumberError', 'submit' ]

  connect() {
    this.set_events();
    this.ChangeNameTimerId = ''
    this.ChangeEmailTimerId = ''
    this.ChangePhoneNumberTimerId = ''
  }

  handleChangeName() {
    clearTimeout(this.ChangeNameTimerId)

    this.ChangeNameTimerId = setTimeout(() => {
      this.isValidName()
    }, 1000);
  }

  isValidName() {
    const nameInput = this.nameTarget
    const nameError = this.nameErrorTarget

    if (!nameInput.value) {
      nameInput.style.border = '1px solid red'
      nameError.textContent = 'お名前を入力してください'
      nameError.style.color = 'red'

      return false
    }
    this.removeErrorDisplay(nameInput, nameError)

    return true
  }

  handleChangeEmail() {
    clearTimeout(this.ChangeEmailTimerId)

    this.ChangeNameTimerId = setTimeout(() => {
      this.isValidEmail()
    }, 1000)
  }

  isValidEmail() {
    const emailInput = this.emailTarget
    const emailError = this.emailErrorTarget

    // NOTE: URI::MailTo::EMAIL_REGEXP と同様の正規表現
    // /\A[a-zA-Z0-9.!\#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*\z/
    const validEmailRegex = /^[a-zA-Z0-9.!\#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/

    if(!validEmailRegex.test(emailInput.value)) {
      emailInput.style.border = '1px solid red'
      emailError.textContent = '有効なメールアドレスを入力してください'
      emailError.style.color = 'red'

      return false
    }
    this.removeErrorDisplay(emailInput, emailError)

    return true
  }

  handleChangePhoneNumber(){
    clearTimeout(this.ChangePhoneNumberTimerId)

    this.ChangePhoneNumberTimerId = setTimeout(() => {
      this.isValidPhoneNumber()
    }, 1000)
  }

  isValidPhoneNumber() {
    const phoneNumberInput = this.phoneNumberTarget
    const phoneNumberError = this.phoneNumberErrorTarget
    // NOTE: Feedback::VALID_PHONE_NUMBER_REGEX と同様の正規表現
    // /\A(((0(\d{1}[-(]?\d{4}|\d{2}[-(]?\d{3}|\d{3}[-(]?\d{2}|\d{4}[-(]?\d{1}|[5789]0[-(]?\d{4})[-)]?)|\d{1,4}\-?)\d{4}|0120[-(]?\d{3}[-)]?\d{3})\z/
    const validPhoneNumberRegex = /^(((0(\d{1}[-(]?\d{4}|\d{2}[-(]?\d{3}|\d{3}[-(]?\d{2}|\d{4}[-(]?\d{1}|[5789]0[-(]?\d{4})[-)]?)|\d{1,4}\-?)\d{4}|0120[-(]?\d{3}[-)]?\d{3})$/

    if(!validPhoneNumberRegex.test(phoneNumberInput.value)) {
      phoneNumberInput.style.border = '1px solid red'
      phoneNumberError.textContent = '有効な電話番号を入力してください'
      phoneNumberError.style.color = 'red'

      return false
    }
    this.removeErrorDisplay(phoneNumberInput, phoneNumberError)

    return true
  }

  async validateFrom(e) {
    await this.isValidName()
    await this.isValidEmail()
    await this.isValidPhoneNumber()

    if(this.isValidName() && this.isValidEmail() && this.isValidPhoneNumber()) {
      return
    }
    this.nameTarget.scrollIntoView({ behavior: 'smooth', block: 'start' })

    e.stopPropagation()
    e.preventDefault()
  }

  removeErrorDisplay(inputElement, errorElement) {
    inputElement.style.border = null
    errorElement.textContent = null
    errorElement.style.color = null
  }

  /*
   * イベント設定
   */
  set_events() {
    $(function(){
      $('label.custom-checkbox input[type=checkbox]').change(function () {
        updateButtonText(countChecked());
      });
    });
    function countChecked() {
      var set = new Set($('label.custom-checkbox input[type=checkbox]:checked').map(function() { return this.value }));
      return set.size;
    }
    function updateButtonText(n) {
      var t = "この内容で見学予約（無料）";
      if(n > 0) {
        t = (n+1) + "件の物件を見学予約（無料）"
      }
      $('.js-submit').text(t);
    }
  }
}
