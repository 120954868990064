/* global google */

export default class {
  constructor() {
    this.content = document.createElement('div');
    this.content.classList.add('estate-map-info-window-content');

    const pixelOffset = document.createElement('div');
    pixelOffset.classList.add('estate-map-info-window-anchor');
    pixelOffset.appendChild(this.content);

    this.anchor = document.createElement('div');
    this.anchor.classList.add('estate-map-info-window-wrapper');
    this.anchor.appendChild(pixelOffset);
    this.anchor.style.display = 'none';
  }

  onAdd = () => {
    this.getPanes().floatPane.appendChild(this.anchor);
  }

  onRemove = () => {
    if (this.anchor.parentElement) {
      google.maps.event.clearInstanceListeners(this.content);
      this.anchor.parentElement.removeChild(this.anchor);
      this.anchor = null;
    }
  }

  draw = () => {
    const projection = this.getProjection();

    if (!this.position || !projection || this.anchor.style.display === 'none')
      return;

    const divPosition = projection.fromLatLngToDivPixel(this.position);
    const display = Math.abs(divPosition.x) < 4000 && Math.abs(divPosition.y) < 4000 ?
      'block' : 'none';

    if (display === 'block') {
      this.anchor.style.left = divPosition.x + 'px';
      this.anchor.style.top = divPosition.y + 'px';
    }

    if (this.anchor.style.display !== display) {
      this.anchor.style.display = display;
    }
  }

  open = (estateId, position) => {
    this.estateId = estateId;
    this.position = position;
    this.anchor.style.display = 'block';
    this.draw();
  }

  close = () => {
    this.anchor.style.display = 'none';
  }

  openEstate = () => {
    window.open(`/estates/${this.estateId}`, '_blank');
  }

  setContent = ({ image, price, name, roomplan, address, station }) => {
    if (price == null || roomplan == null) {
      this.content.innerHTML = `<div class="estate-map-info-window-content-inner">
        <img src="${image}">
        <div class="estate-map-info-window-content-data">
          <div class="estate-map-info-window-content-data__name">${name}</div>
          <div class="estate-map-info-window-content-data__address">${address}</div>
          <div class="estate-map-info-window-content-data__address">${station}</div>
        </div>
      </div>`;
    } else {
      this.content.innerHTML = `<div class="estate-map-info-window-content-inner">
        <img src="${image}">
        <div class="estate-map-info-window-content-data">
          <div class="estate-map-info-window-content-data__price">${price}</div>
          <div class="estate-map-info-window-content-data__name">${name}</div>
          <ul class="estate-map-info-window-content-data__others">
            <li class="estate-map-info-window-content-data__others-item">${roomplan}</li>
          </ul>
          <div class="estate-map-info-window-content-data__address">${address}</div>
          <div class="estate-map-info-window-content-data__address">${station}</div>
        </div>
      </div>`;
    }
  }
}
