import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'source', 'copyButton' ]

  copy() {
    navigator.clipboard.writeText(this.sourceTarget.value)

    $('.copy-succeeded-message').fadeIn('slow', function () {
      $(this).delay(2000).fadeOut('slow')
    })
  }
}
