/* global google */

export default class {
  static dispatchCallbackEvent() {
    window.dispatchMapEvent = function(...args) {
      const event = document.createEvent('Events');
      event.initEvent('google-map-callback', true, true);
      event.args = args;
      window.dispatchEvent(event);
    };
  }

  static options() {
    return {
      minZoom: 9,
      maxZoom: 19,
      disableDefaultUI: true,
      zoomControl: true,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      styles: [
        {
          'featureType': 'poi',
          'elementType': 'labels',
          'stylers': [{ 'visibility': 'off' }]
        },
        {
          'featureType': 'road',
          'elementType': 'labels',
          'stylers': [{ 'visibility': 'off' }]
        }
      ]
    };
  }
}
