import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    this.set_events();
  }

  /*
   * イベント設定
   */
  set_events() {
    // ファイルアップ（ブラウザ内）時のバリデーション
    function validateFiles(files) {
      let acceptableContentType = [
        'image/jpeg',
        'image/png',
        'application/pdf',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ];

      if (files.length) {
        if (files.length > 1) {
          alert('1ファイルずつアップして下さい');
          return false
        } else if (!acceptableContentType.includes(files[0].type)) {
          alert('このファイルは選択できません');
          return false
        } else {
          return true
        }
      } else {
        return false
      }
    }

    // ファイルアップ（ブラウザ内）時にファイル名を表示
    function showFiles(files) {
      if (validateFiles(files)) {
        let output = $('.upload-result');

        output.append('<p class="file-item">' + files[0].name + '<span class="close"></span></p>');
        $('.btn-attatch').addClass('disabled');
        $('.btn-attatch input').prop('disabled', true);
        $('#chat_form_submit').removeClass('stop_submit');
      } else {
        $('.btn-attatch input').val('')
        if ($.trim($('#chat_form_textarea').val()).length === 0) {
          $('#chat_form_submit').addClass('stop_submit');
        }
      }
    }

    // ファイルアップ（ブラウザ内）
    $('.btn-attatch input').off().on('change', function(e) {
      showFiles(e.target.files);
    });

    // ファイルの削除
    $('body').on('click', '.file-item .close', function() {
      $('.btn-attatch').removeClass('disabled');
      $('.btn-attatch input').prop('disabled', false);
      $('.btn-attatch input').val('')
      if ($.trim($('#chat_form_textarea').val()).length === 0) {
        $('#chat_form_submit').addClass('stop_submit');
      }
      $(this).parent().fadeOut(300, function() {
        $(this).remove();
      });
    });

    $(window).on('load resize', function() {
      $('.message-wrap').height( $(window).height() - $('#header').outerHeight() - $('.breadcrumb').outerHeight() - 30 );
    });

    // メッセージorファイルが空白の状態は送信させない
    $('#chat_form_textarea').on('keydown keyup keypress change', function() {
      if ($.trim($(this).val()).length === 0 && !$('.btn-attatch').hasClass('disabled')) {
        $('#chat_form_submit').addClass('stop_submit');
      } else {
        $('#chat_form_submit').removeClass('stop_submit');
      }
    });

    // サブミット中の多重送信を止める
    $('#chat_form_submit').on('click', function() {
      // fileのdisabledを解除しないとファイルが送信されない
      $('.btn-attatch input').prop('disabled', false);
      $(this).addClass('stop_submit');
    });
  }
}
