import { Controller } from 'stimulus';
import Rails from 'rails-ujs';

export default class extends Controller {
  static targets = [ 'checkBox' ]

  handleCheckBoxChange() {
    if (this.checkBoxTarget.checked){
      if (confirm('フリエから一言を表示してよろしいですか？')){
        this.updateColumnToDisplay();
      } else {
        this.checkBoxTarget.checked = false;
      }
    } else {
      if (confirm('フリエから一言を非表示にしてよろしいですか？')){
        this.updateColumnToDisplay();
      } else {
        this.checkBoxTarget.checked = true;
      }
    }
  }

  updateColumnToDisplay(){
    const estateId = this.checkBoxTarget.defaultValue
    const columnToDisplay = this.checkBoxTarget.checked;

    let formData = new FormData();
    formData.append('column_to_display', columnToDisplay);

    Rails.ajax({
      type: 'PATCH',
      dataType: 'json',
      url: `/api/estates/${estateId}/update_column_to_display`,
      data: formData,
      contentType: 'application/json',
      success: (json) => {
        if (json.column_to_display) {
          alert('フリエから一言を表示しました')
        } else {
          alert('フリエから一言を非表示にしました')
        }
      },
      error: () => {
        alert('エラーが発生しました。ブラウザをリロードして、再度お試しください。')
      }
    });
  }
}
