import Rails from 'rails-ujs';
import { Controller } from 'stimulus';
import 'selectize'

export default class extends Controller {
  static targets = ['salesBeginDate', 'updateSalesBeginDateButton']

  connect() {
    this.modalElement = document.getElementById('client-estate');
  }

  editSalesStartedAt(event) {
    event.preventDefault();

    this.modalElement.dataset.estateId = event.target.dataset.estateId;

    const estateCreatedAt = event.target.dataset.estateCreatedAt;
    const formattedDate = estateCreatedAt.split(' ')[0];

    this.modalElement.querySelector('#created_at_input').value = formattedDate;

    const modal = new bootstrap.Modal(this.modalElement);
    modal.show();
  }

  updateSalesBeginDate() {
    this.updateSalesBeginDateButtonTarget.disabled = true;

    const estateId = this.modalElement.dataset.estateId;
    const inputDate = this.salesBeginDateTarget.value;

    let formData = new FormData();
    formData.append('sales_begin_date', inputDate);

    Rails.ajax({
      type: 'PUT',
      url: `/client/estates/${estateId}`,
      data: formData,
      success: () => {
        alert('販売開始日を更新しました');
        this.closeModal()
        location.reload();
      },
      error: (response) => {
        alert(response.error);
        this.closeModal()
        location.reload();
      },
    });
  }

  closeModal() {
    const modal = bootstrap.Modal.getInstance(this.modalElement);
    modal.hide();
  }
}
