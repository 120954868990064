import { Controller } from 'stimulus';
import LoanSimulator from '../classes/loan_simulator';

export default class extends Controller {
  static targets = ['downpayment', 'rate', 'years', 'result', 'total', 'borrowing', 'expenses']

  initialize() {
    this.setSlider();
    this.updateResult();
  }

  updateSlider(e) {
    this.updateResult();
  }

  setSlider() {
    $('.slider-range').each(function(e) {
      let $this = $(this);
      let value = $this.data('value'),
      min = $this.data('min'),
      max = $this.data('max');

      $this.slider({
        range: 'min',
        value: value,
        min: min,
        max: max,
        slide: function( event, ui ) {
          $this.prev('input').val( ui.value );
          let simulatorId = $this.parent().attr('data');

          if( $this.hasClass('percent-slider') ) {
            $this.parent().siblings().children('input').val((ui.value / 10000).toFixed(3));
          } else {
            $this.parent().siblings().children('input').val(ui.value);
          }
          let price = parseInt($('#simulator').data().simulatorPrice);
          let downpayment = parseInt($(`#input-price-${simulatorId}`).val() * 10000);
          let yearRate = parseFloat($(`#input-interest-${simulatorId}`).val());
          let payYears = parseInt($(`#input-repayment-year-${simulatorId}`).val());
          const monthRate = (yearRate / 100.0) / 12.0;
          const payMonths = payYears * 12;
          const payPerMonth = ((price - downpayment) * monthRate * (1.0 + monthRate)**payMonths) / ((1.0 + monthRate)**payMonths - 1.0);
          const totalPay = payPerMonth * payYears * 12.0;
          const intPayPerMonth = parseInt(payPerMonth);
          const intTotalPay = parseInt(totalPay);
          if (Number.isNaN(intPayPerMonth)) {
            $(`#simulator-result-${simulatorId}`).html('-');
          } else {
            const formatPayPerMonth = intPayPerMonth.toString().replace(/([0-9]+?)(?=(?:[0-9]{3})+$)/g , '$1,');
            $(`#simulator-result-${simulatorId}`).html(formatPayPerMonth);
          }
          if (Number.isNaN(intTotalPay)) {
            $(`#simulator-total-${simulatorId}`).html('-');
          } else {
            const formatTotalPay = intTotalPay.toString().replace(/([0-9]+?)(?=(?:[0-9]{3})+$)/g , '$1,');
            $(`#simulator-total-${simulatorId}`).html(formatTotalPay);
          }
          $(`#simulator-borrowing-${simulatorId}`).html((price / 10000 - downpayment / 10000).toLocaleString('ja-JP'))
        }
      });

      if( $this.hasClass('percent-slider') ) {
        $this.parent().siblings().children('input').val(($this.slider('value') / 10000).toFixed(3));
      } else {
        $this.parent().siblings().children('input').val($this.slider('value'));
      }
    });
  }

  updateResult() {
    const payYears = parseInt(this.yearsTarget.value);
    const pay = LoanSimulator.payPerMonth(
      this.price,
      parseInt(this.downpaymentTarget.value) * 10000,
      parseFloat(this.rateTarget.value),
      payYears
    );
    const totalPay = pay * payYears * 12.0;
    this.resultTarget.innerHTML = this.numberFormat(parseInt(pay));
    this.totalTarget.innerHTML = this.numberFormat(parseInt(totalPay));
    this.borrowingTarget.innerHTML = (this.toManYen(parseInt(this.price) - parseInt(this.downpaymentTarget.value) * 10000)).toLocaleString('ja-JP')
    let lowerLimitExpenses = Math.round(this.toManYen(this.price * 0.065 - this.price * 0.95 * 0.03)).toLocaleString('ja-JP')
    let upperLimitExpenses = Math.round(this.toManYen(this.price * 0.08 - this.price * 0.95 * 0.03)).toLocaleString('ja-JP')
    this.expensesTarget.innerHTML = lowerLimitExpenses + '〜' + upperLimitExpenses
  }

  loanDeductionChecked(e) {
    if (e.currentTarget.checked) {
      let lowerLimitExpenses = Math.round(this.toManYen(this.price * 0.065 - this.price * 0.95 * 0.03 - this.price / 200 - 100000)).toLocaleString('ja-JP')
      let upperLimitExpenses = Math.round(this.toManYen(this.price * 0.08 - this.price * 0.95 * 0.03 - this.price / 200 + 100000)).toLocaleString('ja-JP')
      this.expensesTarget.innerHTML = lowerLimitExpenses + '〜' + upperLimitExpenses
    } else {
      let lowerLimitExpenses = Math.round(this.toManYen(this.price * 0.065 - this.price * 0.95 * 0.03)).toLocaleString('ja-JP')
      let upperLimitExpenses = Math.round(this.toManYen(this.price * 0.08 - this.price * 0.95 * 0.03)).toLocaleString('ja-JP')
      this.expensesTarget.innerHTML = lowerLimitExpenses + '〜' + upperLimitExpenses
    }
  }

  get price() {
    return this.data.get("price")
  }

  numberFormat(n) {
    return Number.isNaN(n) ? '-' : n.toString().replace(/([0-9]+?)(?=(?:[0-9]{3})+$)/g , '$1,');
  }

  toManYen(n) {
    return n / 10000;
  }
}
