import { Controller } from 'stimulus';

export default class extends Controller {
  showModal() {
    document.getElementById('hope_estate_name').textContent = this.hopeEstateName;
    document.getElementById('hope_estate_footprint').textContent = this.hopeEstateFootprint;
    document.getElementById('hope_estate_prefecture').textContent = this.hopeEstatePrefecture + ' ' + this.hopeEstateCity;
    document.getElementById('hope_estate_floor').textContent = this.hopeEstateFloor;
    document.getElementById('hope_estate_direction').textContent = this.hopeEstateDirection;
    document.getElementById('hope_estate_hope_price').textContent = this.hopeEstateHopePrice;
    document.getElementById('hope_estate_accept_similar').textContent = this.hopeEstateAcceptSimilar;
    document.getElementById('hope_estate_motivation').textContent = this.hopeEstateMotivation;
    document.getElementById('hope_estate_loan').textContent = this.hopeEstateLoan;
    document.getElementById('hope_estate_reason').textContent = this.hopeEstateReason;
    document.getElementById('hope_estate_renovation').textContent = this.hopeEstateRenovation;
  }

  get hopeEstateName() {
    return this.data.get('hope-estate-name');
  }

  get hopeEstateFootprint() {
    return this.data.get('hope-estate-footprint');
  }

  get hopeEstatePrefecture() {
    return this.data.get('hope-estate-prefecture');
  }

  get hopeEstateCity() {
    return this.data.get('hope-estate-city');
  }

  get hopeEstateFloor() {
    return this.data.get('hope-estate-floor');
  }

  get hopeEstateDirection() {
    return this.data.get('hope-estate-direction');
  }

  get hopeEstateHopePrice() {
    return this.data.get('hope-estate-hope-price');
  }

  get hopeEstateAcceptSimilar() {
    return this.data.get('hope-estate-accept-similar');
  }

  get hopeEstateMotivation() {
    return this.data.get('hope-estate-motivation');
  }

  get hopeEstateLoan() {
    return this.data.get('hope-estate-loan');
  }

  get hopeEstateReason() {
    return this.data.get('hope-estate-reason');
  }

  get hopeEstateRenovation() {
    return this.data.get('hope-estate-renovation');
  }
}
