/* global google */

export default class {
  constructor(estateId, position) {
    this.estateId = estateId;
    this.position = position;
    this.focusedClassName = 'estate-map-marker-hover';
    this.content = document.createElement('div');
    this.content.classList.add('estate-map-marker');
  }

  onAdd = () => {
    this.getPanes().overlayMouseTarget.appendChild(this.content);
  }

  onRemove = () => {
    if (this.content.parentElement) {
      google.maps.event.clearInstanceListeners(this.content);
      this.content.parentElement.removeChild(this.content);
      this.content = null;
    }
  }

  draw = () => {
    const projection = this.getProjection();

    if (!this.position || !projection)
      return;

    const divPosition = projection.fromLatLngToDivPixel(this.position);
    const display = Math.abs(divPosition.x) < 4000 && Math.abs(divPosition.y) < 4000 ?
      'block' : 'none';

    if (display === 'block') {
      this.content.style.left = divPosition.x + 'px';
      this.content.style.top = divPosition.y + 'px';
    }

    if (this.content.style.display !== display) {
      this.content.style.display = display;
    }
  }

  isFocused = () => {
    return this.content.classList.contains(this.focusedClassName);
  }

  removeAllFocusedClasses = () => {
    Array.from(document.querySelectorAll(`.${this.focusedClassName}`)).forEach(marker => {
      marker.classList.remove(this.focusedClassName);
    });
  }

  prepareForOpeningInfoWindow = () => {
    this.removeAllFocusedClasses();
    this.content.classList.add(this.focusedClassName);
  }

  prepareForClosingInfoWindow = () => {
    this.removeAllFocusedClasses();
  }
}
