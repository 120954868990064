// import Rails from 'rails-ujs';
import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    this.set_events();
  }

  /*
   * イベント設定
   */
  set_events() {
    // 三点メニューの位置調整
    if (!$('#sidebar').hasClass('grid-content__sidebar--notitle')) {
      $('#sidebar').addClass('grid-content__sidebar--notitle');
    }

    // SPの場合チャットIDが指定されていたらチャット画面を表示
    if (window.innerWidth <= 768) {
      if (location.pathname.split('/').length > 3) {
        $('.message-tab').hide();
        $('#chat_area').removeClass('sp-none');
      }
    }
    // PCの場合 /user/messages にアクセスがきたら１番上のチャットを表示
    if (window.innerWidth > 768) {
      const path = location.pathname.split('/');
      if (path.length == 3 && path[1] == 'user' && path[2] == 'messages') {
        if ($('#chat_list li').length > 0) {
          location.href = '/user/messages/' + $($('#chat_list li')[0]).find('a.sp-none').data().chatId;
        }
      }
    }

    // SPチャットリンク
    $('#chat_list_display .chat-link-sp').on('click', function() {
      $('.message-tab').hide();
      $('#chat_area').removeClass('sp-none');
    });

    // チャット一覧スクロール
    // チャット一覧内最下部へスクロールした場合は次ページ取得
    $('#chat_list_display').on('scroll', function() {
      const page_datas = $('#chat_list').data();
      if (page_datas.isLastPage || page_datas.isPageLoading) {
        return false;
      }
      const $chat_list_display = $('#chat_list_display');
      const $chat_list = $('#chat_list');
      const display_height = $chat_list_display.height(); // 表示領域の下部
      const display_scroll_top = $chat_list_display.scrollTop(); //　表示領域のスクロール量
      const list_height = $chat_list.height(); // リストの下部
      const check_height = display_height + display_scroll_top - list_height;

      if (Math.ceil(check_height) >= 0) {
        $('#chat_list').data('isPageLoading', true);
        $.ajax({
          type: 'GET',
          url: '/user/messages/chats_page?page=' + page_datas.nextPage,
          success: () => {},
          error: () => {},
          complete: () => {
            $('#chat_list').data('isPageLoading', false);
          }
        });
      }
    });
  }
}
