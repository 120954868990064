// import Rails from 'rails-ujs';
import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    this.set_events();
  }

  /*
   * イベント設定
   */
  set_events() {
    // チャット一覧スクロール
    // チャット一覧内最下部へスクロールした場合は次ページ取得
    $('#chat_list_display').on('scroll', function() {
      const page_datas = $('#chat_list').data();
      if (page_datas.isLastPage || page_datas.isPageLoading) {
        return false;
      }
      const $chat_list_display = $('#chat_list_display');
      const $chat_list = $('#chat_list');
      const display_height = $chat_list_display.height(); // 表示領域の下部
      const display_scroll_top = $chat_list_display.scrollTop(); //　表示領域のスクロール量
      const list_height = $chat_list.height(); // リストの下部
      const check_height = display_height + display_scroll_top - list_height;

      if (Math.ceil(check_height) >= 0) {
        $('#chat_list').data('isPageLoading', true);
        $.ajax({
          type: 'GET',
          url: '/realtor/messages/chats_page?page=' + page_datas.nextPage,
          success: () => {},
          error: () => {},
          complete: () => {
            $('#chat_list').data('isPageLoading', false);
          }
        });
      }
    });
  }
}
