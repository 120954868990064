export default class {
  constructor({ text }) {
    this.tag = document.createElement('span');
    this.tag.classList.add('is-hidden');
    this.tag.classList.add('estate-map-button');
    this.tag.textContent = text;
  }

  show = () => {
    this.tag.classList.remove('is-hidden');
  }

  hide = () => {
    this.tag.classList.add('is-hidden');
  }
}
