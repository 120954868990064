import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.modalElement = document.getElementById('client-accept-feedback-customer-transfer');
    this.memoElement = this.modalElement.querySelector('.customer-transfer-memo');
    this.contentMemoElement = this.modalElement.querySelector('.content-memo');
    this.contentEmailElement = this.modalElement.querySelector('.content-email');
    this.textareaElement = this.modalElement.querySelector('textarea');

    const placeholderText = 'お客様に送信するメッセージをご記入ください。\n（例）\nこの度は、「○○○マンション」のお問い合わせありがとうございます。\n私、売主○○○の○○○と申します。\n今回担当させていただきます○○○よりご連絡いたします。\nお待たせして申し訳ございません。どうぞよろしくお願いいたします。';
    this.textareaElement.placeholder = placeholderText

    // モーダルが閉じた後のイベントリスナーを設定
    this.modalElement.addEventListener('hidden.bs.modal', () => this.resetModal());
  }

  async acceptCustomerTransfer(event, message, customerTransferId) {
    event.preventDefault();

    try {
      const response = await fetch(`/api/client_customer_transfers/${customerTransferId}/accept`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
        },
        body: JSON.stringify({ message }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      alert('ユーザーにメールを送信しました。');
      location.href = `/client/customer_transfers/${customerTransferId}`;
    } catch (error) {
      alert('メールの送信に失敗しました。');
      location.reload();
    }
  }

  showCreateEmailContent(event) {
    event.preventDefault();

    this.contentMemoElement.classList.add('d-none');
    this.contentEmailElement.classList.remove('d-none');
  }

  showConfirmAcceptContent(event) {
    event.preventDefault();

    this.contentEmailElement.classList.add('d-none');
    this.contentMemoElement.classList.remove('d-none');
  }

  confirmAccept(event) {
    event.preventDefault();

    this.modalElement.dataset.customerTransferId = event.target.dataset.customerTransferId;
    this.memoElement.textContent = event.target.dataset.customerTransferMemo;
    const modal = new bootstrap.Modal(this.modalElement);
    modal.show();
  }

  confirmSubmit(event) {
    event.preventDefault();
    const message = this.textareaElement.value;
    const customerTransferId = this.modalElement.dataset.customerTransferId;
    if (!message) {
      alert('メッセージを入力してください。');
      return;
    }

    const result = confirm('ご記入いただいたメッセージをお客様にメールで送信いたします。\n送信すると課金処理が行われます。\nお客様の情報を確認し、電話やメールなどでやりとりを始めてください。');
    if (result) {
      this.acceptCustomerTransfer(event, message, customerTransferId);
    }
  }


  // モーダルが閉じたときに実行されるリセット関数
  resetModal() {
    // 表示文言を初期値に戻す
    this.memoElement.textContent = '読み込み中...';

    // content-memoを表示し、content-emailを非表示にする
    this.contentMemoElement.classList.remove('d-none');
    this.contentEmailElement.classList.add('d-none');

    // textareaの値をクリアする
    this.textareaElement.value = '';
  }
}
