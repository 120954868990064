import Rails from 'rails-ujs';
import { Controller } from 'stimulus';

export default class extends Controller {
  initialize() {
    this.get_ga_estate_uu();
  }

  connect() {
    this.set_events();
  }

  // 〇〇人が検討中を表示
  // NOTE: GAのAPIを呼び出しているが処理が遅いので非同期で取得
  get_ga_estate_uu() {
    var estate_id = $('#estate_id').val();
    Rails.ajax({
      type: 'GET',
      dataType: 'json',
      url: '/api/ga/estate_uu/' + estate_id,
      success: (data) => {
        if (data != null && data > 0) {
          $('#ga_estate_uu').html('現在' + data + '人が検討中');
        }
      },
      error: () => {},
      complete: () => {}
    });
  }

  /*
   * イベント設定
   */
  set_events() {
    $(function(){
      // 無料通話
      $(".js_tel_click_text").click(function(){
        var estate_id = $('#estate_id').val();
        $.get({
          url: '/api/estates/' + estate_id + '/phone_number',
          success: function(data){
            $(".js_tel_click_text").hide();

            $(".js_tel_text").append(data['result']['formatted_number']);
            $(".js_tel_text a.estates-show-contact__action").attr("href", 'tel:' + data['result']['number']);
            $(".js_tel_text").show();
            $(".js_tel_click_text").parent(".estates-show-contact__tel-number-pc").css({"border" : "1px solid #F66B6B"})
          }
        })
      });

      var mainSlider = $('.main-slider');

      mainSlider.slick({
        dots: true,
        customPaging: function (slider, i) {
          return  (i + 1) + '/' + slider.slideCount;
        }
      });

      mainSlider.on('afterChange', function(event, slick, currentSlide) {
        if($(event.target).find('.slick-current [data-slick]').data('slick') === 'room_plan_btn') {
          $('.btn-detail-sp').show();
        } else if ($(event.target).find('.slick-slide [data-slick]').length > 0) {
          $('.btn-detail-sp').hide();
        }

        $('.thumb-item').removeClass('slick-active');
        $('.thumb-slider .thumb-item[data-slick="'+currentSlide+'"]').addClass('slick-active');
      });

      $('.thumb-slider').slick({
        dots: true,
        appendArrows: $('.thumb-arrows'),
        prevArrow: '<span class="slick-prev"></span>',
        nextArrow: '<span class="slick-next"></span>',
        responsive: [
        {
          breakpoint: 992,
          settings: {
            dots: false
          }
        }
        ]
      });

      $('.thumb-slider .thumb-item').on('click', function() {
        let slickIndex = $(this).data('slick');
        $('.thumb-item').removeClass('slick-active');
        $(this).addClass('slick-active');
        $('.main-slider').slick('slickGoTo', slickIndex)
      });

      $('.simulator-box__input input').on('change', function() {
        let value = $(this).val();

        if($(this).parent().hasClass('label-interest')) {
          $(this).parent().siblings().find('.slider-range').slider("value", value*10000);
        } else {
          $(this).parent().siblings().find('.slider-range').slider("value", value);
        }
      });

      $('.show-popup').on('click', function(e) {
        e.preventDefault();
        let dataID = $(this).data('id');

        $('.popup[data-id="'+dataID+'"]').fadeIn().scrollTop(0);
        stopScroll();
      });

      $(window).on('scroll', function() {
        let scrollTop = $(this).scrollTop()
        if (window.matchMedia('(max-device-width: 767px)').matches) {
          //if ( $(this).scrollTop() >=　$('.fade-out:visible').offset().top ) {
          //  $('.fixed-btn').stop().fadeOut(100)
          //} else {
          //  $('.fixed-btn').stop().fadeIn(100)
          //}
        } else {
          if ( scrollTop >= $('.detail-slider:visible').offset().top ) {
            $('.fixed-btn').stop().fadeIn(100)
            let fadeOutheight = 0
            if ( $('#recently-viewed-estates').length > 0 ) {
              fadeOutheight = $('.fade-out:visible').offset().top
            } else {
              fadeOutheight = $('.banner-link').offset().top
            }
            if ( scrollTop >= fadeOutheight ) {
              $('.fixed-btn').stop().fadeOut(100)
            }
          } else {
            $('.fixed-btn').stop().fadeOut(100);
          }
        }
      })

      var scrollTop = 0;
      var scrollLeft = 0;
      function stopScroll() {
        scrollTop = $(window).scrollTop();
        scrollLeft = $(window).scrollLeft();

        $('html').addClass('noscroll').css('top', (-scrollTop) + 'px');
      }

      //「上に戻る」ボタン
      var topbtn = $('.return-btn');
      topbtn.hide();
      $(window).scroll(function() {
        if($(this).scrollTop() > (( $(document).height() / 100) * 25)){
          topbtn.fadeIn();
        } else {
          topbtn.fadeOut();
        }
      });
    });

    $(document).ready(function() {
      var th_rowspan = 0;
      $('table.adjustment tr').each(function() {
        $(this).children('td').each(function() {
          // 空の場合、td と直前の th を削除
          if ($(this).text().length === 0) {
            $(this).prev('th').remove()
            $(this).remove()
          }
        })

        if ($(this).children('td').length === 0) {
          // 要素がない場合、trを削除
          $(this).remove()
        } else if ($(this).children('td').length === 1 && th_rowspan < 2) {
          // 行内の要素が1つかつ、rowspanが2未満の項目の行を伸ばす
          // HACK: rowspanが3以上の表示
          // HACK: rowspan: 1, colspan 3 固定
          var td = $(this).find('td')
          $(td).prev('th').attr("rowspan", "1")
          $(td).attr("colspan", "3").attr("rowspan", "1")
        }
        th_rowspan = $(this).find('th').attr("rowspan")
        th_rowspan = !th_rowspan ? 0 : th_rowspan
      })

      // tr がない場合、項目自体を削除
      $('.article.adjustment').each(function() {
        if ($(this).find('tr').length !== 0) return;
        if ($(this).find('li').length !== 0) return;
        $(this).remove();
      })

      let str_monthly_price = $('#simulator-result-info').text()
      $('#monthlyPrice').append(str_monthly_price)

      let int_monthly_price = Number(str_monthly_price.replace('円', '').split(',').join(''))
      switch (true) {
        case int_monthly_price < 1000:
          $('#monthlyPriceFirstNum').append(Math.floor(int_monthly_price / 10000 * 100) / 100)
          break
        case int_monthly_price < 10000:
          $('#monthlyPriceFirstNum').append(Math.floor(int_monthly_price / 10000 * 10) / 10)
          break
        default:
          $('#monthlyPriceFirstNum').append(Math.floor(int_monthly_price / 10000))
      }
    });
  }
}
