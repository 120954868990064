import Rails from 'rails-ujs';
import { Controller } from 'stimulus';
import Suggestrap from 'suggestrap'
import 'selectize'
import $ from 'jquery'

export default class extends Controller {
    // submitButton/forSearchは任意
    // forSearchTargetが存在するとfor_search: trueなlineのみ表示する
    // submitButtonは検索ボタンを想定
  static targets = [ 'prefecture', 'line', 'station', 'submitButton', 'forSearch', 'lineForm' ]

  prefectureChanged() {
    this.lineTarget.innerHTML = '';
    this.stationTarget.innerHTML = '';
    if (this.prefectureTarget.value === "") {
        this.renderLineSelectOptions([])
        this.renderStationSelectOptions([])
    } else {
        this.getAccess({ prefecture_id: this.prefectureTarget.value });
        this.renderStationSelectOptions([])
    }
    // if (this.hasSubmitButtonTarget) {
    //     this.submitButtonTarget.disabled = this.lineTarget.value === "";
    // }
  }

  connect() {
    this.element['estate-station-search'] = this;

    if (this.hasLineTarget) {
      new Suggestrap({
        target: this.lineTarget.id,
        values: JSON.parse(this.lineTarget.dataset.lines).map((line) => {
          return { name: line }
        }),
        key: 'name'
      }, {
        minlength: 1,
        delay: 0,
        count: 100
      })
      $(this.lineTarget).on('blur', () => {
        // 選択した候補がすぐに反映されないため、少し待つ
        setTimeout(() => {
          this.lineChanged(this.lineTarget.value)
        }, 100);
      })
    }

    if (this.hasLineFormTarget) {
      $(this.lineFormTarget).selectize({
        onChange: (value) => {
          this.lineChanged(value)
        }
      })
    }
  }

  lineChanged(value) {
    let accessPromise = this.getAccess(value)
    this.stationTarget.innerHTML = '';
    // if (this.prefectureTarget.value === "") {
    //     this.renderStationSelectOptions([])
    // } else {
    //     this.getAccess({ prefecture_id: this.prefectureTarget.value, line_name: this.lineTarget.value });
    // }
    if (this.hasSubmitButtonTarget) {
        this.submitButtonTarget.disabled = this.lineTarget.value === "";
    }

    return accessPromise;
  }

  getAccess(params) {
    const self = this;
    let url = `/api/accesses?line_name=${params}`
    return new Promise(function(resolve, reject) {
      Rails.ajax({
        type: 'GET',
        dataType: 'json',
        url: url,
        success: (json) => {
          self.renderStationSelectOptions(json.stations);
            resolve(json);
        },
        error: () => {
          self.showAlert();
          reject();
        }
      });
    });
  }

  renderLineSelectOptions(results) {
      this.renderSelectOptions(this.lineTarget, results, "路線");
  }

  renderStationSelectOptions(results) {
      this.renderSelectOptions(this.stationTarget, results, "駅");
  }

  renderSelectOptions(target, results, default_text) {
    if (!results)
      return;

    const option = document.createElement('option');
    option.text = default_text;
    option.value = "";
    target.add(option);
    results.forEach(place => {
      const option = document.createElement('option');
      option.value = place.name;
      option.text = place.name;
      target.add(option);
    });
  }

  showAlert() {
    alert('エラーが発生しました。ブラウザをリロードして、再度お試しください。');
  }
}
