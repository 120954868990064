import Rails from 'rails-ujs';
import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    this.replace_url(this.chatPath);
    this.chat_read(this.chatId, this.chatMemberType, this.chatProxyMember);
    this.first_view_scroll(this.chatIsFirstView, this.chatReadAt, this.chatDisplayingEndPostedAt, this.chatLinkChatContentId)
  }

  get chatId() {
    return this.data.get('id');
  }

  get chatPath() {
    return this.data.get('path');
  }

  get chatMemberType() {
    return this.data.get('member-type');
  }

  get chatIsFirstView() {
    return this.data.get('is-first-view');
  }

  get chatDisplayingBeginPostedAt() {
    return this.data.get('displaying-begin-posted-at');
  }

  get chatDisplayingEndPostedAt() {
    return this.data.get('displaying-end-posted-at');
  }

  get chatReadAt() {
    return this.data.get('read-at');
  }

  get chatProxyMember() {
    return this.data.get('proxy-member');
  }

  get chatLinkChatContentId() {
    return this.data.get('link-chat-content-id');
  }

  replace_url(chat_path) {
    history.replaceState('', '', chat_path);
  }

  chat_read(chat_id, chat_member_type, proxy_member) {
    let api_url = '';
    if (chat_member_type == 'realtor') {
      if (proxy_member == 'admin') {
        api_url = '/api/chats/' + chat_id + '/admin_read';
      } else {
        api_url = '/api/chats/' + chat_id + '/realtor_read';
      }
    } else if (chat_member_type == 'user') {
      api_url = '/api/chats/' + chat_id + '/user_read';
    }

    if (api_url != '') {
      Rails.ajax({
        type: 'POST',
        dataType: 'json',
        url: api_url,
        success: (data) => {
          const unread_array = data;
          const unread_mark = 'has-new';
          const global_message_link = document.getElementById('global_message_link');
          const chat_message_tab_title = document.getElementById('chat_message_tab_title');
          if (unread_array.length) {
            if (!chat_message_tab_title.classList.contains(unread_mark)) {
              global_message_link.classList.add(unread_mark);
              chat_message_tab_title.classList.add(unread_mark);
              if (chat_member_type == 'user') {
                const header_global_message_link = document.getElementById('header_global_message_link');
                header_global_message_link.classList.add(unread_mark);
              }
            }
          } else {
            if (chat_message_tab_title.classList.contains(unread_mark)) {
              global_message_link.classList.remove(unread_mark);
              chat_message_tab_title.classList.remove(unread_mark);
              if (chat_member_type == 'user') {
                const header_global_message_link = document.getElementById('header_global_message_link');
                header_global_message_link.classList.remove(unread_mark);
              }
            }
          }
        },
        error: () => {},
        complete: () => {}
      });
    }
  }

  first_view_scroll(chat_is_first_view, chat_read_at, chat_displaying_end_posted_at, chat_link_chat_content_id) {
    // ページ初期表示の場合はスクロールしない
    if (!chat_is_first_view) {
      return false;
    }

    // チャットコンテンツID指定の場合は該当メッセージを表示
    if (chat_link_chat_content_id) {
      const chat_area_items = document.getElementsByClassName('chat_area_item');
      const chat_area_items_array = Array.prototype.slice.call(chat_area_items);
      chat_area_items_array.forEach(function(value, index, array) {
        if (index > 0) {
          if (value.dataset.chatContentId == chat_link_chat_content_id) {
            this.chat_area_scroll(array[index - 1], -56);
          }
        }
      }, this);
      this.chatLinkChatContentIdValue = null;
      return false;
    }

    // 既読なしの場合はスクロールしない
    if (!chat_read_at) {
      return false;
    }

    const chat_area_items = document.getElementsByClassName('chat_area_item');
    // チャット履歴が1件のみは一番上を表示
    if (chat_area_items.length <= 1) {
      return false;
    }
    // 最新のチャットまで閲覧している場合には一番下を表示
    if (chat_displaying_end_posted_at <= chat_read_at) {
      this.chat_area_scroll(chat_area_items[chat_area_items.length - 1]);
      return false;
    }
    // 未読がある場合は未読の開始位置を表示
    const chat_area_items_array = Array.prototype.slice.call(chat_area_items);
    chat_area_items_array.forEach(function(value, index, array) {
      if (index > 0) {
        const posted_at = value.dataset.postedAt;
        const just_before_posted_at = array[index - 1].dataset.postedAt;
        if (posted_at > chat_read_at && just_before_posted_at <= chat_read_at) {
          value.insertAdjacentHTML('beforebegin','<div class="first_view_unread">未読メッセージ</div>');
          this.chat_area_scroll(array[index - 1], -50);
        }
      }
    }, this);
  }

  chat_area_scroll(element, buffer = 0) {
    const rect = element.getBoundingClientRect(); // 移動させたい位置の要素を取得
    const position = rect.top;    // 一番上からの位置を取得
    const area = document.getElementById('chat_area_display');
    area.scrollTo(0, position + buffer);
  }
}
