import { Controller } from 'stimulus';

export default class extends Controller {
  initialize() {
    this.set_events();
  }

  /*
   * イベント設定
   */
  set_events() {
    $(document).ready(() => {
      this.scroll_first_view();
    }, this);

    $(window).on('hashchange', () => {
      this.scroll_first_view();
    });
  }

  /*
   * 初回表示位置移動
   * urlにハッシュ値がある場合は指定のページまでスクロールしタブ切り替え
   * @note ハッシュ値は #loan or #subscribe の2つ。タブのidと同じ
   * @note タブ切り替えは共通のjsで行われるが、`trigger('click')` で切り替えているため、
   *       スクロール位置が戻ってしまう。そのため、独自で切り替える必要がある。
   * @see https://github.com/mansion-market/flie/blob/stg/app/assets/javascripts/script.js#L31-L39
   */
  scroll_first_view() {
    const url_hash = location.hash;
    const loan_hash = '#loan';
    const subscribe_hash = '#subscribe';
    if ([loan_hash, subscribe_hash].includes(url_hash)) {
      switch (url_hash) {
      case loan_hash:
        $('#flie_agent_original_service_tab_loan').parent().addClass('active');
        $('#flie_agent_original_service_tab_subscribe').parent().removeClass('active');
        $(loan_hash).show();
        $(subscribe_hash).hide();
        break;
      case subscribe_hash:
        $('#flie_agent_original_service_tab_loan').parent().removeClass('active');
        $('#flie_agent_original_service_tab_subscribe').parent().addClass('active');
        $(loan_hash).hide();
        $(subscribe_hash).show();
        break;
      }
      // 少しだけ上部に余白を持たせるため10で調整
      $(window).scrollTop($('#flie_agent_original_service_tab').offset().top - 10);
    } else {
      $('#flie_agent_original_service_tab_loan').parent().addClass('active');
      $('#flie_agent_original_service_tab_subscribe').parent().removeClass('active');
      $(loan_hash).show();
      $(subscribe_hash).hide();
      $(window).scrollTop();
    }
  }
}
