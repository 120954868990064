import Rails from 'rails-ujs';
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'headerCountAll',
    'headerCountClosed',
    'headerCountPublished',
    'headerCountCancelled',
    'headerCountSold',
    'headerCountUnreleased',
    'headerCountWithDeleted'
  ]

  connect() {
    this.loadAdminEstates()
  }

  loadAdminEstates() {
    const url = new URL(window.location.href);

    Rails.ajax({
      type: 'GET',
      dataType: 'json',
      url: url.pathname + url.search,
      success: (json) => {
        // ヘッダー: ステータス別件数取得
        this.headerCountAllTarget.textContent = json.count_by_statuses.all
        this.headerCountClosedTarget.textContent = json.count_by_statuses.closed
        this.headerCountPublishedTarget.textContent = json.count_by_statuses.published
        this.headerCountCancelledTarget.textContent = json.count_by_statuses.cancelled
        this.headerCountSoldTarget.textContent = json.count_by_statuses.sold
        this.headerCountUnreleasedTarget.textContent = json.count_by_statuses.unreleased
        this.headerCountWithDeletedTarget.textContent = json.count_by_statuses.with_deleted
      },
      error: (json) => {
        console.error("Error fetching count:", error)
      }
    })
  }
}
