/* eslint-disable */
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['realtorForm', 'realtorIpAddress'];

  destroyMainPhoto() {
    const photo = document.getElementById('realtor_main_photo');
    const photo_hidden = document.getElementById('realtor_main_photo_hidden');
    photo.value = '';
    photo_hidden.value = '';
    photo_hidden.closest('.m-file-input__preview').innerHTML = 'プレビュー';
  }

  destroyLogoPhoto() {
    const photo = document.getElementById('realtor_logo_photo');
    const photo_hidden = document.getElementById('realtor_logo_photo_hidden');
    photo.value = '';
    photo_hidden.value = '';
    photo_hidden.closest('.m-file-input__preview').innerHTML = 'プレビュー';
  }

  validateForm(event) {
    const tmpIps = this.realtorIpAddressTargets.map((ip) => ip.value);
    const ips = tmpIps.filter(item => item !== '' && item !== null);

    if (ips.length > 0 && !ips.includes(this.currentIpAddress)) {
      if (!confirm('「現在アクセス中のIPアドレス」が設定されていないため、現在の環境からでは接続できなくなります\n\n本当によろしいですか？')) {
        event.preventDefault();
      }
    }
  }

  get currentIpAddress() {
    return this.data.get('current-ip-address');
  }
}
