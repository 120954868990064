import flatpickr from 'flatpickr';
import { Japanese } from 'flatpickr/dist/l10n/ja.js';

document.addEventListener('DOMContentLoaded', () => {
  const now = new Date();
  // 明日の日付を初期設定
  let minSelectableDate = now
  minSelectableDate.setDate(minSelectableDate.getDate() + 1);

  // 18時以降なら翌々日を最小選択可能日にする
  if (now.getHours() >= 18) {
    minSelectableDate.setDate(minSelectableDate.getDate() + 1);
  }

  flatpickr('.js-date-picker', {
    locale: Japanese,
    minDate: minSelectableDate,
    altInput: true,
    altFormat: 'Y年m月d日',
    dateFormat: 'Y-m-d',
    disableMobile: 'true',
    allowInput: true
  });

  flatpickr('.js-date-picker-from-service-start', {
    locale: Japanese,
    minDate: '2018-07-01',
    maxDate: new Date(),
    altInput: true,
    altFormat: 'Y年m月d日',
    dateFormat: 'Y-m-d',
    disableMobile: 'true'
  });
});
