import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [ 'minPrice', 'maxPrice', 'room', 'minFootprint', 'maxFootprint', 'currentState', 'walkingMinute', 'feature', 'form', 'keyword', 'prefecture', 'city' ]

  formChanged(event) {
    const target = event.currentTarget;

    switch (target.name) {
    case 'min_price':
      this.searchController.minPriceTarget.value = this.minPriceTarget.value;
      this.searchController.disableSelectOptions(this.minPriceTarget, this.maxPriceTarget);
      break;
    case 'max_price':
      this.searchController.maxPriceTarget.value = this.maxPriceTarget.value;
      break;
    case 'rooms[]':
      this.toggleCheckedValue(target, this.searchController.roomTargets);
      break;
    case 'min_footprint':
      this.searchController.minFootprintTarget.value = this.minFootprintTarget.value;
      this.searchController.disableSelectOptions(this.minFootprintTarget, this.maxFootprintTarget);
      break;
    case 'max_footprint':
      this.searchController.maxFootprintTarget.value = this.maxFootprintTarget.value;
      break;
    case 'current_state':
      this.toggleCheckedValue(target, this.searchController.currentStateTargets);
      break;
    case 'walking_minute':
      this.toggleCheckedValue(target, this.searchController.walkingMinuteTargets);
      break;
    case 'features[]':
      this.toggleCheckedValue(target, this.searchController.featureTargets);
      break;
    case 'prefecture_id':
      this.searchController.prefectureTarget.value = this.prefectureTarget.value;
      break;
    case 'city_ids[]':
      this.searchController.cityTarget.innerHTML = this.cityTarget.innerHTML;

      Array.prototype.slice.call(this.searchController.cityTarget.options).forEach((elem, idx) => {
        elem.selected = Array.prototype.slice.call(this.cityTarget.selectedOptions).some(inelem => inelem.value == elem.value);
      });

      break;
    }

    this.searchController.formChanged(event);
  }

  toggleCheckedValue(target, searchTargets) {
    const searchTarget = searchTargets.find(searchTarget => {
      return searchTarget.value === target.value;
    });
    searchTarget.checked = target.checked;
  }

  toggleForm(event) {
    event.currentTarget.classList.toggle('is-active');
    this.formTarget.style.display = this.formTarget.style.display === 'none' ? 'block' : 'none';
  }

  formSubmitted(event) {
    event.stopPropagation();
    event.preventDefault();
    this.searchController.keywordTarget.value = this.keywordTarget.value;
    this.searchController.formTarget.submit();
  }

  get searchController() {
    return this.application.getControllerForElementAndIdentifier(
      this.element,
      'estate-search'
    );
  }
}
