import Rails from 'rails-ujs';
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [ 'checkBox', 'isListedOnFlie' ]

  onCheck() {
    if (this.checkBoxTarget.checked){
      if (confirm('[Smaview] 登録してよろしいですか？')){
        this.setSmaviewAvailable()
      } else {
        this.checkBoxTarget.checked = false
      }
    } else {
      if (confirm('[Smaview] 解除してよろしいですか？')){
        this.setSmaviewAvailable()
      } else {
        this.checkBoxTarget.checked = true
      }
    }
  }

  setSmaviewAvailable(){
    Rails.ajax({
      type: 'PATCH',
      dataType: 'json',
      url: `/api/estates/${ this.checkBoxTarget.defaultValue }/update_smaview_available?smaview_available=${ this.checkBoxTarget.checked }`,
      success: (json) => {
        if (json.smaview_available) {
          alert('[Smaview] 登録しました')
        } else {
          alert('[Smaview] 解除しました')
        }
      },
      error: (json) => {
        alert('エラーが発生しました。ブラウザをリロードして、再度お試しください。')
      }
    })
  }

  handleIsListedOnFlieChange() {
    if (this.isListedOnFlieTarget.checked) {
      if (confirm('FLIEの掲載状態を有効にしますか？')) {
        this.updateIsListedOnFlie();
      } else {
        this.isListedOnFlieTarget.checked = false;
      }
    } else {
      if (confirm('FLIEの掲載状態を無効にしますか？')) {
        this.updateIsListedOnFlie();
      } else {
        this.isListedOnFlieTarget.checked = true;
      }
    }
  }

  updateIsListedOnFlie() {
    const estateId = this.isListedOnFlieTarget.defaultValue;
    const isListedOnFlie = this.isListedOnFlieTarget.checked;

    let formData = new FormData();
    formData.append('is_listed_on_flie', isListedOnFlie);

    Rails.ajax({
      type: 'PATCH',
      dataType: 'json',
      url: `/api/estates/${estateId}/update_is_listed_on_flie`,
      data: formData,
      contentType: 'application/json',
      success: (json) => {
        alert(`FLIEの掲載状態を${json.is_listed_on_flie ? '有効' : '無効'}にしました`);
      },
      error: () => {
        alert('エラーが発生しました。ブラウザをリロードして、再度お試しください。');
      }
    });
  }
}
