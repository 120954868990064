import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'minPrice', 'maxPrice', 'minFootprint', 'maxFootprint']

  connect() {
    this.disableSelectOptions(this.minPriceTarget, this.maxPriceTarget)
    this.disableSelectOptions(this.minFootprintTarget, this.maxFootprintTarget)
  }

  selectChanged(e) {
    switch (e.currentTarget.name) {
    case 'condition[min_price]':
      this.disableSelectOptions(this.minPriceTarget, this.maxPriceTarget)
      break
    case 'condition[min_footprint]':
      this.disableSelectOptions(this.minFootprintTarget, this.maxFootprintTarget)
      break
    }
  }

  disableSelectOptions(minTarget, maxTarget) {
    const minValue = Number(minTarget.value)

    Array.from(maxTarget.options).forEach(option => {
      const maxValue = Number(option.value)
      if (maxValue === 0)
        return

      if (minValue >= maxValue) {
        option.disabled = true
        if (option.selected)
          maxTarget.options[0].selected = true
      } else {
        option.disabled = false
      }
    })
  }
}
