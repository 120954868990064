import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    const store = {
      userId: '',
      client: '',
      accessToken: '',
      expiry: 0
    }

    const NEW_ESTATE_LIFF_ID = this.liffId

    liffInit()

    async function liffInit() {
      await liff.init({ liffId: NEW_ESTATE_LIFF_ID })

      const { userId } = liff.getContext()
      store.userId = userId
      const accessToken = liff.getAccessToken()
      await signIn(store.userId, accessToken)

      const res = await makeSignedInState()
      if (res.status !== 200) {
        const data = await res.json();
        alert(data.message)
        liff.closeWindow()

        return
      }

      location.href = '/user/new_estates'
    }

    async function signIn(userId, accessToken) {
      const res = await fetch('/liff/auth/sign_in', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          login: userId,
          access_token: accessToken
        })
      })

      if (res.status === 200) {
        store.client = res.headers.get('client');
        store.accessToken = res.headers.get('access-token');
        store.expiry = res.headers.get('expiry')

        return
      }

      if (res.status !== 401 && res.status !== 404) {
        const data = await res.json();
        console.error(data);

        throw new Error(`Status Code: ${res.status}.`)
      }
    }

    async function makeSignedInState() {
      // 保存したアクセストークンを使用し、APIを呼んでログイン状態にする
      const res = await fetch('/liff/user/new_estates/make_signed_in_state', {
        headers: {
          'Content-Type': 'application/json',
          ...getAuthHeaders()
        }
      })

      return res
    }

    // 保存しているアクセストークンなどからリクエストヘッダを生成
    function getAuthHeaders() {
      return {
        uid: store.userId,
        client: store.client,
        expiry: store.expiry,
        'access-token': store.accessToken
      }
    }
  }

  get liffId() {
    return this.data.get('liffId')
  }
}
